@charset "UTF-8";

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, dialog, figure, footer, header,
hgroup, menu, nav, section,
time, mark, audio, video, button {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: top;
  background: transparent;
}

html {
  height: 100% !important;
  touch-action: manipulation;
  -ms-touch-action: manipulation;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-overflow-scrolling: touch;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'M PLUS 1p', sans-serif, Lato, Meiryo, "Noto Sans JP", "游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  color: #333;
  width: 100%;
  background-color: #fff;
  font-size: 14px;
  word-wrap: break-word;
  min-height: 100%;
  padding-top: 32px;
  padding-bottom: 74px;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

.clearfix:after {
  clear: both;
  display: block;
  content: "";
}

video {
  width: 100%;
  height: 100%;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

a {
  color: #077CF8;
  text-decoration: none;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

ul {
  margin: 0;
  list-style: none;
}

ul:after {
  clear: both;
  display: block;
  content: "";
}

/*** table ***/
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

/*
  table.table-striped tr:nth-of-type(2n+1) {
    background-color: #F9F9F9; }*/
table.table-box th {
  background-color: #445466;
  color: #fff;
}

table th,
table td {
  padding: 4px;
  border: 1px solid #DDD;
  vertical-align: middle;
  white-space: pre-wrap;
}

table tr.active-box td {
  background: #E4F3EF;
}

/*
table tr.active-box td {
  position: relative;
}
table tr.active-box td:after {
  position: absolute;
  left: -1px;
  top: -1px;
  height: 100%;
  width: 100%;
  content: "";
  border-top: 1px #1ABC9C solid;
  border-left: 1px #1ABC9C solid;
  border-bottom: 1px #1ABC9C solid;
  display: block;
}
table tr.active-box td:last-child:after {
  border-right: 1px #1ABC9C solid;
}
*/
.fixed-table-container {
  height: 300px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px #ccc solid;
}

.fixed-table-container tr:last-child td {
  border-top: none;
}

.fixed-table-container tr td,
.fixed-table-container tr th {
  border-top: none;
  border-left: none;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  white-space: nowrap;
}

.wrap {
  min-width: 1016px;
  margin: 0 auto;
}

.container-fluid {
  /*
  margin: 0 auto;
  max-width: 1024px;
  */
  padding: 0 16px;
}

.container-wrap {
  margin: 0 auto;
  max-width: 1570px;
  padding-top: 30px;
}

/** margin **/
.mt-8 {
  margin-top: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-64 {
  margin-top: 64px;
}

.mt-80 {
  margin-top: 80px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-64 {
  margin-bottom: 64px;
}

.mb-80 {
  margin-bottom: 80px;
}

.ml-8 {
  margin-left: 8px;
}

.mr-8 {
  margin-right: 8px;
}

.ml-16 {
  margin-left: 16px;
}

.mr-16 {
  margin-right: 16px;
}

/** padding **/
.pt-8 {
  padding-top: 8px;
}

.pt-16 {
  padding-top: 16px;
}

.pt-24 {
  padding-top: 24px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-64 {
  padding-top: 64px;
}

.pt-80 {
  padding-top: 80px;
}

.pb-8 {
  padding-bottom: 8px;
}

.pb-16 {
  padding-bottom: 16px;
}

.pb-24 {
  padding-bottom: 24px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-64 {
  padding-bottom: 64px;
}

.pb-80 {
  padding-bottom: 80px;
}

.color-danger {
  color: #ff3b30;
  font-size: 12px;
}

.color-blue {
  color: #738BA2;
}

/** label-span **/
.label-span {
  border-radius: 15px;
  color: #fff;
  padding: 0 12px 0;
  text-align: center;
  font-size: 12px;
}

.label-span.label-red {
  color: #fff;
  background-color: #E27DA0;
}

/** page **/
.page ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.page ul li:last-child a {
  border-radius: 0 4px 4px 0;
  color: #077CF8;
}

.page ul li:first-of-type a {
  border-radius: 4px 0 0 4px;
  color: #777;
}

.page ul li span,
.page ul li a {
  background: #DDD;
  display: block;
  padding: 4px 16px;
  color: #73879C;
  text-decoration: none;
}

.page ul li span {
  color: #fff;
}

.wrapper .slidebar {
  background: #2A3F54;
  border-right: 1px solid #707070;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 230px;
  z-index: 101;
}

.wrapper .main {
  width: calc(100% - 230px);
  float: right;
}

.footer {
  height: 37px;
}

.footer,
.fix-bottom {
  position: fixed;
  bottom: 0;
  left: 230px;
  z-index: 10;
  width: calc(100% - 230px);
  background: #EDEDED;
  padding: 8px;
  border-top: 1px #707070 solid;
  text-align: center;
  font-size: 14px;
}

.fix-bottom.fix-bottom-pull,
.footer.footer-pull {
  width: 100%;
  left: 0;
}

.fix-bottom {
  bottom: 37px;
  background: #fff;
  padding: 3px 16px;
}

.fix-bottom .btn {
  min-width: 110px;
}

.main .top-nav {
  left: 230px;
  width: calc(100% - 230px);
}

.top-nav {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 10;
  border-bottom: 1px #707070 solid;
  padding: 2px 16px;
  background: #EDEDED;
}

.top-nav .breadcrumb {
  float: left;
}

.top-nav .breadcrumb ul li {
  float: left;
}

.top-nav .breadcrumb ul li:first-of-type a {
  padding-left: 0;
}

.top-nav .breadcrumb ul li:first-of-type a:after {
  content: "";
}

.top-nav .breadcrumb ul li img {
  max-height: 16px;
  margin: -5px 0 0;
}

.top-nav .breadcrumb ul li a {
  display: block;
  color: #333;
  position: relative;
  padding-left: 24px;
  padding: 2px 4px 2px 24px;
}

.top-nav .breadcrumb ul li a:after {
  position: absolute;
  top: calc(50% - 14px);
  left: 8px;
  font-family: 'FontAwesome';
  content: "\f105";
  font-size: 18px;
}

.top-nav .navbar-right {
  float: right;
}

.top-nav .navbar-right ul li {
  float: left;
  border-right: 1px #ccc solid;
}

.top-nav .navbar-right ul li:last-child {
  border: none;
}

.top-nav .navbar-right ul li a {
  padding: 2px 16px;
  display: block;
  color: #333;
}

.top-nav .navbar-right ul li a img {
  max-height: 12px;
  padding-right: 8px;
  margin-top: -2px;
}

.title {
  border-bottom: solid 1px #707070;
  position: fixed;
  width: calc(98% - 230px);
  left: 245px;
  top: 30px;
  background: #fff;
  z-index: 5;
}

.wrap-container .title {
  width: 98%;
  left: 1%;
}

.tlt-fs28 {
  color: #73879C;
  font-size: 28px;
  font-weight: 600;
}

.tlt-fs24 {
  color: #73879C;
  font-size: 24px;
  font-weight: 600;
}

.tlt-fs18 {
  color: #73879C;
  font-size: 18px;
  font-weight: 600;
}

.tablefixedhead {
  border-bottom: 1px solid #ddd;
}

.tablefixedhead .box-tbodytable .table tbody tr:last-child td {
  /* border-bottom: 0; */
}

.tablefixedhead .box-theadtable {
  overflow: hidden;
}

.tablefixedhead .box-tbodytable {
  max-height: 500px;
  overflow: auto;
  position: relative;
}

.box-tbodytable tr:nth-of-type(2n+2) {
  background-color: #f9f9f9;
}

.tablefixedhead .table-codelist thead tr th {
  padding: 4px 3px;
}

.tablefixedhead .table-codelist thead tr th:nth-child(1) {
  min-width: 60px;
  max-width: 60px;
}

.tablefixedhead .table-codelist thead tr th:nth-child(2) {
  min-width: 150px;
  max-width: 150px;
}

.tablefixedhead .table-codelist thead tr th:nth-child(3) {
  min-width: 200px;
  max-width: 200px;
}

.tablefixedhead .table-codelist thead tr th:nth-child(4) {
  min-width: 250px;
  max-width: 250px;
}

.tablefixedhead .table-codelist thead tr th:nth-child(5) {
  min-width: 100px;
  max-width: 100px;
}

.tablefixedhead .table-codelist thead tr th:nth-child(6) {
  min-width: 100px;
  max-width: 100px;
}

.tablefixedhead .table-codelist thead tr th:nth-child(7) {
  min-width: 70px;
  max-width: 70px;
}

.tablefixedhead .table-codelist tbody tr td {
  padding: 4px 3px;
}

.tablefixedhead .table-codelist tbody tr td:nth-child(1) {
  min-width: 60px;
  max-width: 60px;
}

.tablefixedhead .table-codelist tbody tr td:nth-child(2) {
  min-width: 150px;
  max-width: 150px;
}

.tablefixedhead .table-codelist tbody tr td:nth-child(3) {
  min-width: 200px;
  max-width: 200px;
}

.tablefixedhead .table-codelist tbody tr td:nth-child(4) {
  min-width: 250px;
  max-width: 250px;
}

.tablefixedhead .table-codelist tbody tr td:nth-child(5) {
  min-width: 100px;
  max-width: 100px;
}

.tablefixedhead .table-codelist tbody tr td:nth-child(6) {
  min-width: 100px;
  max-width: 100px;
}

.tablefixedhead .table-codelist tbody tr td:nth-child(7) {
  min-width: 70px;
  max-width: 70px;
}

/*** base ***/
.slidebar .logo {
  padding: 16px 16px 0;
}

.slidebar .id-name {
  padding: 32px 16px;
  font-size: 16px;
  color: #fff;
}

.slidebar .nav {
  margin-top: 32px;
  overflow-y: auto;
}

.slidebar .nav.hg-cdms {
  height: calc(100vh - 162px);
}

.slidebar .nav.hg-ctms {
  height: calc(100vh - 122px);
}

.slidebar .nav > ul > li {
  border-top: 1px #425668 solid;
}

.slidebar .nav > ul > li:last-child {
  border-bottom: 1px #425668 solid;
}

.slidebar .nav > ul > li > a {
  padding: 8px 16px 8px 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  position: relative;
}

.slidebar .nav > ul > li > a span {
  position: absolute;
  top: calc(50% - 14px);
  left: 16px;
}

.slidebar .nav > ul > li > a img {
  max-height: 16px;
}

.slidebar .nav > ul > li > a:after {
  position: absolute;
  top: calc(50% - 10px);
  right: 16px;
  font-family: 'FontAwesome';
  content: "\f107";
  color: #fff;
  font-size: 18px;
}

.slidebar .nav > ul > li > a.active {
  -webkit-box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
}

.slidebar .nav > ul > li > a.active:after {
  content: "\f106";
}

.slidebar .nav .nav-sub {
  display: none;
}

.slidebar .nav .nav-sub ul li {
  position: relative;
}

.slidebar .nav .nav-sub ul li:before {
  background: #425668;
  border-radius: 50%;
  bottom: auto;
  content: "";
  left: 20px;
  top: calc(50% - 4px);
  position: absolute;
  right: auto;
  width: 8px;
  height: 8px;
  z-index: 1;
}

.slidebar .nav .nav-sub ul li:after {
  border-left: 1px solid #425668;
  bottom: 0;
  content: "";
  left: 24px;
  position: absolute;
  top: 0;
}

.slidebar .nav .nav-sub ul li a {
  padding: 4px 8px 4px 56px;
  display: block;
  color: #fff;
}

.slidebar .nav .nav-sub ul li a:hover {
  background: #374A5E;
}

.datepicker {
  padding-bottom: 1px !important;
}

.datepicker .table-condensed thead tr th.dow {
  font-size: 14px;
  color: #26B99A;
}

.datepicker .table-condensed thead tr th.dow:first-child {
  color: #ff0000;
}

.datepicker .table-condensed thead tr th.dow:last-child {
  color: #000AFF;
}

.datepicker .table-condensed tbody tr td {
  font-size: 14px;
  height: 28px;
  width: 28px;
  padding: 2px;
}

.datepicker .table-condensed tbody tr td:first-child {
  color: #ff0000;
}

.datepicker .table-condensed tbody tr td:last-child {
  color: #000AFF;
}

.datepicker .table-condensed tfoot tr {
  border-top: 1px solid #ccc;
}

.datepicker .table-condensed tfoot tr th {
  cursor: default;
}

.datepicker .table-condensed tfoot tr th:hover {
  background: transparent;
}

.datepicker .table-condensed tfoot tr th .groupfooter:after {
  content: "";
  display: block;
  clear: both;
}

.datepicker .table-condensed tfoot tr th .groupfooter .textleft {
  float: left;
  font-size: 12px;
  color: #000AFF;
  font-weight: normal;
  line-height: 24px;
  cursor: pointer;
}

.datepicker .table-condensed tfoot tr th .groupfooter .button-calendar {
  float: right;
}

.datepicker .table-condensed tfoot tr th .groupfooter .button-calendar .btn-green-small {
  font-size: 12px;
  padding: 2px 8px;
  height: auto;
}

/*** modules ***/
/***/
.iris_toggle-container {
  -moz-user-select: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  position: relative;
}

.iris_toggle-container label {
  cursor: pointer;
  margin: 0;
}

.iris_toggle-container .iris_toggle-checkbox {
  opacity: 0;
  position: absolute;
}

.iris_toggle-container .iris_toggle-checkbox:checked + .iris_toggle:before {
  left: 55%;
  background: #fff;
}

.iris_toggle-container .iris_toggle-checkbox:checked + .iris_toggle {
  background: #1ABC9C;
}

.iris_toggle-container .iris_toggle {
  border-radius: 16px;
  display: inline-block;
  padding: 12px 0;
  vertical-align: middle;
  background: #999;
  width: 66px;
  height: 32px;
  position: relative;
  -moz-appearance: none;
  outline: 0 none;
  overflow: hidden;
}

.iris_toggle-container .iris_toggle:before {
  background: #fff;
  border-radius: 50%;
  content: "";
  display: block;
  left: 2px;
  width: 28px;
  height: 28px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s cubic-bezier(0.17, 0.67, 0.53, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.17, 0.67, 0.53, 1) 0s;
  transition: all 0.3s cubic-bezier(0.17, 0.67, 0.53, 1) 0s;
}

.btn {
  -moz-user-select: none;
  border: 1px solid transparent;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  -webkit-transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, -webkit-box-shadow 0.15s ease-in-out 0s;
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, -webkit-box-shadow 0.15s ease-in-out 0s;
  -o-transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s, -webkit-box-shadow 0.15s ease-in-out 0s;
  vertical-align: middle;
  white-space: nowrap;
  font-size: 15px;
  padding: 0 16px;
  height: 30px;
  -webkit-transition: 200ms all;
  -o-transition: 200ms all;
  transition: 200ms all;
}

.btn:hover,
.btn:focus {
  text-decoration: none;
  box-shadow: none !important;
}
.btn:hover {
  opacity: 0.7;
}
.btn:focus,
.btn.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  outline: 0 none;
  /* opacity: 1 !important; */
  opacity: 0.7;
}

.btn.disabled, .btn:disabled {
  opacity: 1;
  background: #c3c3c3;
  border-color: #c3c3c3;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn-primary {
  background-color: #187cc2;
  border: 1px #187cc2 solid;
  color: #fff;
}

.btn-green {
  background-color: #26B99A;
  border: 1px #26B99A solid;
  color: #fff;
}

.btn-gray {
  background-color: #F5F5F5;
  border: 1px #169F85 solid;
  color: #000;
}

.btn-red {
  background-color: #FF9999;
  border: 1px #707070 solid;
  color: #000;
}

.btn-red-fl {
  background-color: #DE8282;
  border: 1px #DE8282 solid;
  color: #fff;
}

.btn-yellow {
  background-color: #CC9F07;
  border: 1px #707070 solid;
  color: #000;
}

.btn-purple {
  background-color: #A2A2CE;
  border: 1px #A2A2CE solid;
  color: #fff;
}

.btn-redfl {
  background-color: #F5F5F5;
  border: 1px #FF9999 solid;
}

/**** update file ***/
.custom-file {
  position: relative;
  padding: 0;
}

.custom-file .btn {
  height: auto;
}

.custom-file .btn.btn-txt {
  color: #187cc2;
  font-size: 10px;
  margin-top: 4px;
}

.custom-file-input {
  opacity: 0;
  display: none;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

.form-control {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
  display: block;
  font-size: 14px;
  line-height: 1.6;
  -webkit-transition: border-color 0.15s ease-in-out 0s, -webkit-box-shadow 0.15s ease-in-out 0s;
  transition: border-color 0.15s ease-in-out 0s, -webkit-box-shadow 0.15s ease-in-out 0s;
  -o-transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s, -webkit-box-shadow 0.15s ease-in-out 0s;
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  appearance: none;
  height: 30px;
  border-radius: 3px;
  padding: 0 8px;
}

.form-control.form-control-search {
  background-image: url(../images/icon-search.png);
  background-repeat: no-repeat;
  background-position: right 8px center;
  padding-right: 24px;
}

.form-control.form-control-date {
  background-image: url(../images/icon-date.png);
  background-repeat: no-repeat;
  background-position: right 8px center;
  padding-right: 24px;
}

.form-control.form-control.form-control-time {
  background-image: url(../images/icon-time.png);
  background-repeat: no-repeat;
  background-position: right 8px center;
  padding-right: 24px;
}

.form-control.form-controlbox-yellow {
  background: #FFFDCB;
}

.form-control:focus {
  background-color: #fff;
  border-color: #80bdff;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  color: #495057;
  outline: 0 none;
}

.form-control.form-control-danger {
  border: 1px #ff3b30 solid;
  background: #ffeae8;
}

textarea {
  overflow: auto;
}

textarea.form-control {
  height: auto;
}

select::-ms-expand {
  display: none;
}

select.form-control {
  background: url(../images/select.png) no-repeat scroll right 6px center #fff;
  padding-right: 20px;
}

select.form-controlbox-yellow {
  background: url(../images/select.png) no-repeat scroll right 6px center #FFFDCB;
}

select.form-controlbox-yellow:focus::-ms-value {
  background: url(../images/select.png) no-repeat scroll right 6px center #FFFDCB;
}

select.form-control-danger:focus::-ms-value {
  background: url(../images/select.png) no-repeat scroll right 6px center #ffeae8;
}

/* select.form-control-blue {
  background: url(../images/select-blue.png) no-repeat scroll right 6px center #fff;
  padding-right: 20px; }
  select.form-control-blue.form-controlbox-blue-yellow {
    background: url(../images/select-blue.png) no-repeat scroll right 6px center #FFFDCB; } */

.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

/**** radio checkbox ****/
.custom-control {
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-height: 18px;
  padding-left: 24px;
  position: relative;
}

.custom-control-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.custom-control-input:checked ~ .custom-control-indicator {
  background-color: #1ABC9C;
  border: 1px #1ABC9C solid;
  color: #fff;
}

.custom-control-input:focus ~ .custom-control-indicator {
  -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(26, 188, 156, 0.25);
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(26, 188, 156, 0.25);
}

.custom-control-input:active ~ .custom-control-indicator {
  background-color: #1ABC9C;
  border: 1px #1ABC9C solid;
  color: #fff;
}

.custom-control-input:disabled ~ .custom-control-indicator {
  background-color: #eee;
  border: 1px #ccc solid;
  cursor: not-allowed;
}

.custom-control-input:disabled ~ .custom-control-description {
  color: #636c72;
  cursor: not-allowed;
}

.custom-control-indicator {
  background-color: #fff;
  border: 1px #ccc solid;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  display: block;
  height: 18px;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: calc(50% - 10px);
  width: 18px;
}

.custom-checkbox {
  min-height: 20px;
}

.custom-checkbox .custom-control-indicator {
  width: 20px;
  height: 20px;
}

.custom-checkbox .custom-control-indicator {
  border-radius: 4px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
  background-color: #1ABC9C;
}

.custom-radio .custom-control-indicator {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
  background: url(../images/icon-radio.png) no-repeat 50%;
  border: 1px #1abc9c solid;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  color: #000;
  text-decoration: none;
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: "";
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  width: 100%;
  margin: 0 16px;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }

  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
  transition: -webkit-transform 0.6s ease;
  -webkit-transition: -webkit-transform 0.6s ease;
  -o-transition: transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-next,
  .active.carousel-item-right {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-prev,
  .active.carousel-item-left {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-duration: .6s;
  -o-transition-duration: .6s;
  transition-duration: .6s;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active,
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-prev {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  position: relative;
  -ms-flex: 0 1 auto;
  -webkit-box-flex: 0;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
  /*** modules ***/
.text-throught {
  text-decoration: line-through !important;
  -webkit-text-decoration-color: red !important;
  text-decoration-color: red !important;
}

.text-throught > a {
  text-decoration: line-through !important;
  -webkit-text-decoration-color: red !important;
  text-decoration-color: red !important;
}

.text-throught .text-link {
  -webkit-text-decoration-color: red !important;
  text-decoration-color: red !important;
}

.hidden {
  display: none !important;
}

.btn-green-small {
  background-color: #26B99A;
  border: 1px #26B99A solid;
  color: #fff;
  height: 30px;
}

.form-control-disable {
  text-decoration: line-through;
  -webkit-text-decoration-color: red;
  text-decoration-color: red;
}

.heading-1 {
  font-size: 16px;
  font-weight: bold;
  color: #73879C;
}

.heading-3 {
  font-size: 15px;
  font-weight: bold;
  color: #73879C;
  text-transform: uppercase;
}

.lhcheckbox {
  line-height: 31px;
}

.lh {
  line-height: 30px;
}

.heading-normal {
  font-size: 16px;
  color: #73879C;
}

.scroll-table {
  overflow: auto;
}

.grpform-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.grpform-input .txt-label {
  line-height: 30px;
}

.grpform-input .txt-required {
  line-height: 30px;
  width: 50px;
  margin-right: 10px;
}

.grpform-input .form-control.w120px {
  width: 120px;
}

.grpform-input .form-control.w200px {
  width: 200px;
}

.grpform-input .grpform-radio {
  line-height: 26px;
}

.text-red {
  color: #FF0000;
}

.textlong-2 {
  height: 227px !important;
}

textarea.form-control {
  white-space: pre-wrap;
  word-break: break-all;
}

.txt-peduce {
  color: #fff;
  padding-top: 1px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: inline-block;
  text-align: center;
  margin-right: 5px;
}

.txt-peduce.txt-violet {
  background: #9054D6;
}

.txt-peduce.txt-blue {
  background: #006CFF;
}

.txt-peduce.txt-cyan {
  background: #0AA0BA;
}

.txt-peduce.txt-green {
  background: #1ABC9C;
}

.dropdown.noarrow-dropdown .dropdown-toggle:after {
  display: none;
}

.dropdown.noarrow-dropdown .dropdown-menu {
  width: 100%;
  margin: 0;
}

.textnotemore {
  position: absolute;
  bottom: 84px;
  left: 246px;
  padding: 10px 0 0 16px;
}

.icon-editor {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  cursor: pointer;
}

.icon-editor.icon-plus {
  background: url("../images/icon_play.png") no-repeat center;
  margin-right: 2px;
}

.icon-editor.icon-file {
  background: url("../images/icon_file.png") no-repeat center;
  margin-right: 2px;
}

.icon-editor.icon-change {
  background: url("../images/icon_change.png") no-repeat center;
  margin-right: 2px;
}

.icon-editor.icon-play {
  background: url("../images/icon_add.png") no-repeat center;
}

.icon-editor.icon-downbottom1 {
  background: url("../images/ic_btdown.png") no-repeat center;
}

.icon-editor.icon-upto1 {
  background: url("../images/ic_upto.png") no-repeat center;
}

.icon-editor.icon-vilot {
  background-color: #9054D6;
}

.icon-editor.icon-blue {
  background-color: #006CFF;
}

.icon-editor.icon-cyan {
  background-color: #0AA0BA;
}

.icon-editor.icon-green {
  background-color: #1ABC9C;
}

.bg-vilot {
  background-color: #9054D6 !important;
}

.bg-blue {
  background-color: #006CFF !important;
}

.bg-green {
  background-color: #1ABC9C !important;
}

.icon-deletered {
  background: url("../images/ic_clear_24px.png") no-repeat center;
  width: 24px;
  height: 24px;
  display: inline-block;
  cursor: pointer;
}

.icon-deletered.disabled {
  background: url("../images/ic_clear_disable_24px.png") no-repeat center;
  width: 24px;
  height: 24px;
  display: inline-block;
  cursor: default;
  /*cursor: pointer;*/
}

.icon-keyed {
  background: url("../images/ic_key.png") no-repeat center;
  width: 24px;
  height: 24px;
  display: inline-block;
  cursor: pointer;
}

.icon-keyed {
  background: url("../images/ic_key.png") no-repeat center;
  width: 24px;
  height: 24px;
  display: inline-block;
  cursor: pointer;
}

.icon-keyed.disabled {
  background: url("../images/ic_key_disable.png") no-repeat center;
  cursor: default !important;
}

.icon-restore {
  background: url("../images/restore.png") no-repeat center;
  width: 28px;
  height: 26px;
  display: inline-block;
  cursor: pointer;
}

.icon-restore.disabled {
  background: url("../images/ic_restore_disable.png") no-repeat center;
  width: 28px;
  height: 26px;
  display: inline-block;
  cursor: default;
}

.icon-restore-disable {
  background: url("../images/ic_restore_disable.png") no-repeat center;
  width: 28px;
  height: 26px;
  display: inline-block;
  cursor: default;
}

.text-link {
  color: #000AFF;
  text-decoration: underline;
  cursor: pointer;
}

.icon-plusmini {
  color: #fff;
  width: 20px;
  height: 20px;
  background: #1ABC9C;
  font-style: normal;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
}

.icon-plusmini.disabled {
  background: #C3C3C3;
  cursor: context-menu;
}

.icon-upto {
  color: #fff;
  width: 20px;
  height: 20px;
  background: #1ABC9C;
  font-style: normal;
  border-radius: 3px;
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}

.icon-upto.disabled {
  background: #C3C3C3;
  cursor: context-menu;
}

.icon-downbottom {
  color: #fff;
  width: 20px;
  height: 20px;
  background: #1ABC9C;
  font-style: normal;
  border-radius: 3px;
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}

.icon-downbottom.disabled {
  background: #C3C3C3;
  cursor: context-menu;
}

.icon-searchgreen {
  background: url("../images/icon_searchgreen.png") no-repeat center;
  width: 26px;
  height: 26px;
  display: inline-block;
  cursor: pointer;
}

.icon-searchgreen.disabled {
  background: url("../images/audittrail_disable.png") no-repeat center;
  cursor: context-menu;
}

.icon-copytbl {
  background: url("../images/icon_copy.png") no-repeat center;
  width: 26px;
  height: 26px;
  display: inline-block;
  cursor: pointer;
}

.icon-copytbl.disabled {
  background: url("../images/icon_copy_disabled.png") no-repeat center;
  width: 24px;
  height: 24px;
  display: inline-block;
  cursor: default;
}

.grpagetion {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ant-input[disabled] ,
.ant-select-disabled .ant-select-selection,
.ant-radio-disabled .ant-radio-input,
.ant-radio-disabled + span ,
.ant-radio-disabled .ant-radio-inner ,
.ant-checkbox-disabled,
input[type='radio'][disabled], 
input[type='checkbox'][disabled],
input[type='radio'].disabled, 
input[type='checkbox'].disabled 
{
    cursor: context-menu !important;
}

.grpagetion .rangenumberpage {
  width: 25%;
}

.grpagetion .page {
  width: 75%;
  text-align: right;
}

.modal-itemcreatcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal thead tr th, .modal-itemeditcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal thead tr th, .tablepreview1-modal thead tr th, .tablepreview2-modal thead tr th, .tablepreview3-modal thead tr th {
  background: #F4F4F4;
  font-weight: normal;
  padding: 4px;
}

.modal-itemcreatcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal tbody tr td, .modal-itemeditcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal tbody tr td, .tablepreview1-modal tbody tr td, .tablepreview2-modal tbody tr td, .tablepreview3-modal tbody tr td {
  padding: 4px;
}

.modal-itemcreatcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal tbody tr td .grpform-input .txt-label, .modal-itemeditcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal tbody tr td .grpform-input .txt-label, .tablepreview1-modal tbody tr td .grpform-input .txt-label, .tablepreview2-modal tbody tr td .grpform-input .txt-label, .tablepreview3-modal tbody tr td .grpform-input .txt-label {
  line-height: 30px;
  padding-right: 5px;
}

.modal-itemcreatcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal tbody tr td .grpform-input .txt-unit, .modal-itemeditcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal tbody tr td .grpform-input .txt-unit, .tablepreview1-modal tbody tr td .grpform-input .txt-unit, .tablepreview2-modal tbody tr td .grpform-input .txt-unit, .tablepreview3-modal tbody tr td .grpform-input .txt-unit {
  line-height: 30px;
  padding-left: 5px;
}

.modal-preview .modal-content .modal-header, .modal-confirm .modal-content .modal-header, .modal-sussces .modal-content .modal-header, .modal-ChangePwd .modal-content .modal-header, .modal-HistoryofAudit .modal-content .modal-header, .modal-HistoryofCodeList .modal-content .modal-header, .modal-HistoryOfEcs .modal-content .modal-header, .modal-HistoryofUser .modal-content .modal-header, .modal-importCRF .modal-content .modal-header, .modal-importCodeList .modal-content .modal-header, .modal-importecs .modal-content .modal-header, .modal-itemcreatcode .modal-content .modal-header, .modal-itemeditcode .modal-content .modal-header, .modal-itemcreatversion .modal-content .modal-header {
  position: relative;
  padding: 5px 15px;
  background: #445466;
}

.modal-preview .modal-content .modal-header .modal-title, .modal-confirm .modal-content .modal-header .modal-title, .modal-sussces .modal-content .modal-header .modal-title, .modal-ChangePwd .modal-content .modal-header .modal-title, .modal-HistoryofAudit .modal-content .modal-header .modal-title, .modal-HistoryofCodeList .modal-content .modal-header .modal-title, .modal-HistoryOfEcs .modal-content .modal-header .modal-title, .modal-HistoryofUser .modal-content .modal-header .modal-title, .modal-importCRF .modal-content .modal-header .modal-title, .modal-importCodeList .modal-content .modal-header .modal-title, .modal-importecs .modal-content .modal-header .modal-title, .modal-itemcreatcode .modal-content .modal-header .modal-title, .modal-itemeditcode .modal-content .modal-header .modal-title, .modal-itemcreatversion .modal-content .modal-header .modal-title {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.modal-preview .modal-content .modal-header .close, .modal-confirm .modal-content .modal-header .close, .modal-sussces .modal-content .modal-header .close, .modal-ChangePwd .modal-content .modal-header .close, .modal-HistoryofAudit .modal-content .modal-header .close, .modal-HistoryofCodeList .modal-content .modal-header .close, .modal-HistoryOfEcs .modal-content .modal-header .close, .modal-HistoryofUser .modal-content .modal-header .close, .modal-importCRF .modal-content .modal-header .close, .modal-importCodeList .modal-content .modal-header .close, .modal-importecs .modal-content .modal-header .close, .modal-itemcreatcode .modal-content .modal-header .close, .modal-itemeditcode .modal-content .modal-header .close, .modal-itemcreatversion .modal-content .modal-header .close {
  position: absolute;
  right: 10px;
  top: 5px;
  padding: 0;
  margin: 0;
  color: #fff;
  opacity: 1;
}

.modal-preview .modal-content .modal-body, .modal-confirm .modal-content .modal-body, .modal-sussces .modal-content .modal-body, .modal-ChangePwd .modal-content .modal-body, .modal-HistoryofAudit .modal-content .modal-body, .modal-HistoryofCodeList .modal-content .modal-body, .modal-HistoryOfEcs .modal-content .modal-body, .modal-HistoryofUser .modal-content .modal-body, .modal-importCRF .modal-content .modal-body, .modal-importCodeList .modal-content .modal-body, .modal-importecs .modal-content .modal-body, .modal-itemcreatcode .modal-content .modal-body, .modal-itemeditcode .modal-content .modal-body, .modal-itemcreatversion .modal-content .modal-body {
  padding: 10px 15px;
}

.modal-preview .modal-content .modal-body .grp-buttonfooter .btn, .modal-confirm .modal-content .modal-body .grp-buttonfooter .btn, .modal-sussces .modal-content .modal-body .grp-buttonfooter .btn, .modal-ChangePwd .modal-content .modal-body .grp-buttonfooter .btn, .modal-HistoryofAudit .modal-content .modal-body .grp-buttonfooter .btn, .modal-HistoryofCodeList .modal-content .modal-body .grp-buttonfooter .btn, .modal-HistoryOfEcs .modal-content .modal-body .grp-buttonfooter .btn, .modal-HistoryofUser .modal-content .modal-body .grp-buttonfooter .btn, .modal-importCRF .modal-content .modal-body .grp-buttonfooter .btn, .modal-importCodeList .modal-content .modal-body .grp-buttonfooter .btn, .modal-importecs .modal-content .modal-body .grp-buttonfooter .btn, .modal-itemcreatcode .modal-content .modal-body .grp-buttonfooter .btn, .modal-itemeditcode .modal-content .modal-body .grp-buttonfooter .btn, .modal-itemcreatversion .modal-content .modal-body .grp-buttonfooter .btn {
  min-width: 110px;
}

.modal-preview .modal-content .modal-body .groupflex-md, .modal-confirm .modal-content .modal-body .groupflex-md, .modal-sussces .modal-content .modal-body .groupflex-md, .modal-ChangePwd .modal-content .modal-body .groupflex-md, .modal-HistoryofAudit .modal-content .modal-body .groupflex-md, .modal-HistoryofCodeList .modal-content .modal-body .groupflex-md, .modal-HistoryOfEcs .modal-content .modal-body .groupflex-md, .modal-HistoryofUser .modal-content .modal-body .groupflex-md, .modal-importCRF .modal-content .modal-body .groupflex-md, .modal-importCodeList .modal-content .modal-body .groupflex-md, .modal-importecs .modal-content .modal-body .groupflex-md, .modal-itemcreatcode .modal-content .modal-body .groupflex-md, .modal-itemeditcode .modal-content .modal-body .groupflex-md, .modal-itemcreatversion .modal-content .modal-body .groupflex-md {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal-preview .modal-content .modal-body .groupflex-md .flexleft, .modal-confirm .modal-content .modal-body .groupflex-md .flexleft, .modal-sussces .modal-content .modal-body .groupflex-md .flexleft, .modal-ChangePwd .modal-content .modal-body .groupflex-md .flexleft, .modal-HistoryofAudit .modal-content .modal-body .groupflex-md .flexleft, .modal-HistoryofCodeList .modal-content .modal-body .groupflex-md .flexleft, .modal-HistoryOfEcs .modal-content .modal-body .groupflex-md .flexleft, .modal-HistoryofUser .modal-content .modal-body .groupflex-md .flexleft, .modal-importCRF .modal-content .modal-body .groupflex-md .flexleft, .modal-importCodeList .modal-content .modal-body .groupflex-md .flexleft, .modal-importecs .modal-content .modal-body .groupflex-md .flexleft, .modal-itemcreatcode .modal-content .modal-body .groupflex-md .flexleft, .modal-itemeditcode .modal-content .modal-body .groupflex-md .flexleft, .modal-itemcreatversion .modal-content .modal-body .groupflex-md .flexleft {
  width: 30%;
}

.modal-preview .modal-content .modal-body .groupflex-md .flexright, .modal-confirm .modal-content .modal-body .groupflex-md .flexright, .modal-sussces .modal-content .modal-body .groupflex-md .flexright, .modal-ChangePwd .modal-content .modal-body .groupflex-md .flexright, .modal-HistoryofAudit .modal-content .modal-body .groupflex-md .flexright, .modal-HistoryofCodeList .modal-content .modal-body .groupflex-md .flexright, .modal-HistoryOfEcs .modal-content .modal-body .groupflex-md .flexright, .modal-HistoryofUser .modal-content .modal-body .groupflex-md .flexright, .modal-importCRF .modal-content .modal-body .groupflex-md .flexright, .modal-importCodeList .modal-content .modal-body .groupflex-md .flexright, .modal-importecs .modal-content .modal-body .groupflex-md .flexright, .modal-itemcreatcode .modal-content .modal-body .groupflex-md .flexright, .modal-itemeditcode .modal-content .modal-body .groupflex-md .flexright, .modal-itemcreatversion .modal-content .modal-body .groupflex-md .flexright {
  width: 70%;
}

.modal-preview .modal-dialog {
  max-width: 900px;
}

.modal-preview .modal-content .modal-body {
  padding-bottom: 16px;
}

.modal-confirm .modal-dialog {
  max-width: 450px;
}

.modal-confirm .modal-content .modal-body .form-confirm {
  padding: 20px 0 5px 0;
}

.modal-confirm .modal-content .modal-body .form-confirm .text-confirm {
  text-align: center;
  font-size: 16px;
  padding-bottom: 30px;
}

.modal-confirm .modal-content .modal-body .form-confirm .grp-btnconfirm {
  text-align: center;
}

.modal-confirm .modal-content .modal-body .form-confirm .grp-btnconfirm .btn {
  min-width: 80px;
}

.modal-sussces .modal-dialog {
  max-width: 450px;
}

.modal-sussces .modal-content .modal-body .form-confirm {
  padding: 20px 0 5px 0;
}

.modal-sussces .modal-content .modal-body .form-confirm .text-confirm {
  text-align: center;
  font-size: 16px;
  padding-bottom: 30px;
}

.modal-sussces .modal-content .modal-body .form-confirm .grp-btnconfirm {
  text-align: center;
}

.modal-sussces .modal-content .modal-body .form-confirm .grp-btnconfirm .btn {
  min-width: 80px;
}

.modal-ChangePwd .modal-dialog {
  max-width: 600px;
}

.modal-ChangePwd .modal-content .modal-body {
  padding: 16px;
}

.modal-ChangePwd .modal-content .modal-body .grpform-input .txt-label {
  width: 130px;
}

.modal-ChangePwd .modal-content .modal-body .grpform-input .frm-input {
  width: calc(100% - 130px - 60px);
}

.modal-HistoryofAudit .modal-dialog, .modal-HistoryofCodeList .modal-dialog, .modal-HistoryOfEcs .modal-dialog {
  max-width: 1400px;
}

.modal-HistoryofAudit .modal-content .modal-body, .modal-HistoryofCodeList .modal-content .modal-body, .modal-HistoryOfEcs .modal-content .modal-body {
  padding-top: 16px;
  padding-bottom: 16px;
}

.modal-HistoryofAudit .modal-content .modal-body .box-tableHistoryAudit .grpform-input, .modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .grpform-input, .modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .grpform-input {
  margin-bottom: 8px;
}

.modal-HistoryofAudit .modal-content .modal-body .box-tableHistoryAudit .grpform-input .form-control, .modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .grpform-input .form-control, .modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .grpform-input .form-control {
  width: 100px;
}

.modal-HistoryofAudit .modal-content .modal-body .box-tableHistoryAudit .grpform-input .txt-unit, .modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .grpform-input .txt-unit, .modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .grpform-input .txt-unit {
  line-height: 30px;
  padding-left: 10px;
}

.modal-HistoryofAudit .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal thead tr th, .modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal thead tr th, .modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal thead tr th {
  width: 7%;
  min-width: 100px;
  max-width: 100px;
}

.modal-HistoryofAudit .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal thead tr th:nth-child(1), .modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal thead tr th:nth-child(1), .modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal thead tr th:nth-child(1) {
  width: 3%;
  min-width: 60px;
  max-width: 60px;
}

.modal-HistoryofAudit .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal thead tr th:nth-child(2), .modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal thead tr th:nth-child(2), .modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal thead tr th:nth-child(2) {
  width: 5%;
  min-width: 80px;
  max-width: 80px;
}

.modal-HistoryofAudit .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal thead tr th:nth-child(3), .modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal thead tr th:nth-child(3), .modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal thead tr th:nth-child(3) {
  width: 10%;
  min-width: 120px;
  max-width: 120px;
}

.modal-HistoryofAudit .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal thead tr th:nth-child(4), .modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal thead tr th:nth-child(4), .modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal thead tr th:nth-child(4) {
  width: 23%;
  min-width: 290px;
  max-width: 390px;
}

.modal-HistoryofAudit .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal thead tr th:nth-child(5), .modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal thead tr th:nth-child(5), .modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal thead tr th:nth-child(5) {
  width: 15%;
  min-width: 220px;
  max-width: 220px;
}

.modal-HistoryofAudit .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal thead tr th:nth-child(6), .modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal thead tr th:nth-child(6), .modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal thead tr th:nth-child(6) {
  width: 2%;
  min-width: 50px;
  max-width: 50px;
}

.modal-HistoryofAudit .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal thead tr th:nth-child(7), .modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal thead tr th:nth-child(7), .modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal thead tr th:nth-child(7) {
  width: 10%;
  min-width: 100px;
  max-width: 100px;
}

.modal-HistoryofAudit .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal thead tr th:nth-child(8), .modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal thead tr th:nth-child(8), .modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal thead tr th:nth-child(8) {
  width: 25%;
  min-width: 275px;
  max-width: 275px;
}

.modal-HistoryofAudit .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal tbody tr td, .modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal tbody tr td, .modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal tbody tr td {
  width: 7%;
  min-width: 100px;
  max-width: 100px;
}

.modal-HistoryofAudit .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal tbody tr td:nth-child(1), .modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal tbody tr td:nth-child(1), .modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal tbody tr td:nth-child(1) {
  width: 3%;
  min-width: 60px;
  max-width: 60px;
}

.modal-HistoryofAudit .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal tbody tr td:nth-child(2), .modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal tbody tr td:nth-child(2), .modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal tbody tr td:nth-child(2) {
  width: 5%;
  min-width: 80px;
  max-width: 80px;
}

.modal-HistoryofAudit .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal tbody tr td:nth-child(3), .modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal tbody tr td:nth-child(3), .modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal tbody tr td:nth-child(3) {
  width: 10%;
  min-width: 120px;
  max-width: 120px;
}

.modal-HistoryofAudit .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal tbody tr td:nth-child(4), .modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal tbody tr td:nth-child(4), .modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal tbody tr td:nth-child(4) {
  width: 23%;
  min-width: 290px;
  max-width: 390px;
}

.modal-HistoryofAudit .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal tbody tr td:nth-child(5), .modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal tbody tr td:nth-child(5), .modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal tbody tr td:nth-child(5) {
  width: 15%;
  min-width: 220px;
  max-width: 220px;
}

.modal-HistoryofAudit .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal tbody tr td:nth-child(6), .modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal tbody tr td:nth-child(6), .modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal tbody tr td:nth-child(6) {
  width: 2%;
  min-width: 50px;
  max-width: 50px;
}

.modal-HistoryofAudit .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal tbody tr td:nth-child(7), .modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal tbody tr td:nth-child(7), .modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal tbody tr td:nth-child(7) {
  width: 10%;
  min-width: 100px;
  max-width: 100px;
}

.modal-HistoryofAudit .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal tbody tr td:nth-child(8), .modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal tbody tr td:nth-child(8), .modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAudit-modal tbody tr td:nth-child(8) {
  width: 25%;
  min-width: 275px;
  max-width: 275px;
}

.modal-HistoryofUser .modal-dialog {
  max-width: 1400px;
}

.modal-HistoryofUser .modal-content .modal-body {
  padding-top: 16px;
  padding-bottom: 16px;
}

.modal-HistoryofUser .modal-content .modal-body .box-tableHistoryAudit .grpform-input {
  margin-bottom: 8px;
}

.modal-HistoryofUser .modal-content .modal-body .box-tableHistoryAudit .grpform-input .form-control {
  width: 100px;
}

.modal-HistoryofUser .modal-content .modal-body .box-tableHistoryAudit .grpform-input .txt-unit {
  line-height: 30px;
  padding-left: 10px;
}

.modal-HistoryofUser .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditUser-modal thead tr th {
  width: 7%;
  min-width: 100px;
  max-width: 100px;
}

.modal-HistoryofUser .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditUser-modal thead tr th:nth-child(1) {
  width: 3%;
  min-width: 60px;
  max-width: 60px;
}

.modal-HistoryofUser .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditUser-modal thead tr th:nth-child(2) {
  width: 15%;
  min-width: 200px;
  max-width: 200px;
}

.modal-HistoryofUser .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditUser-modal thead tr th:nth-child(3) {
  width: 15%;
  min-width: 200px;
  max-width: 200px;
}

.modal-HistoryofUser .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditUser-modal thead tr th:nth-child(4) {
  width: 6%;
  min-width: 80px;
  max-width: 80px;
}

.modal-HistoryofUser .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditUser-modal thead tr th:nth-child(5) {
  width: 10%;
  min-width: 100px;
  max-width: 100px;
}

.modal-HistoryofUser .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditUser-modal thead tr th:nth-child(6) {
  width: 10%;
  min-width: 100px;
  max-width: 100px;
}

.modal-HistoryofUser .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditUser-modal thead tr th:nth-child(7) {
  width: 11%;
  min-width: 110px;
  max-width: 110px;
}

.modal-HistoryofUser .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditUser-modal thead tr th:nth-child(8) {
  width: 20%;
  min-width: 250px;
  max-width: 250px;
}

.modal-HistoryofUser .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditUser-modal tbody tr td {
  width: 7%;
  min-width: 100px;
  max-width: 100px;
}

.modal-HistoryofUser .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditUser-modal tbody tr td:nth-child(1) {
  width: 3%;
  min-width: 60px;
  max-width: 60px;
}

.modal-HistoryofUser .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditUser-modal tbody tr td:nth-child(2) {
  width: 15%;
  min-width: 200px;
  max-width: 200px;
}

.modal-HistoryofUser .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditUser-modal tbody tr td:nth-child(3) {
  width: 15%;
  min-width: 200px;
  max-width: 200px;
}

.modal-HistoryofUser .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditUser-modal tbody tr td:nth-child(4) {
  width: 6%;
  min-width: 80px;
  max-width: 80px;
}

.modal-HistoryofUser .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditUser-modal tbody tr td:nth-child(5) {
  width: 10%;
  min-width: 100px;
  max-width: 100px;
}

.modal-HistoryofUser .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditUser-modal tbody tr td:nth-child(6) {
  width: 10%;
  min-width: 100px;
  max-width: 100px;
}

.modal-HistoryofUser .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditUser-modal tbody tr td:nth-child(7) {
  width: 11%;
  min-width: 110px;
  max-width: 110px;
}

.modal-HistoryofUser .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditUser-modal tbody tr td:nth-child(8) {
  width: 20%;
  min-width: 250px;
  max-width: 250px;
}

.modal-HistoryofCodeList .modal-dialog {
  max-width: 1000px;
}

.modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditCodeList-modal thead tr th {
  min-width: 100px;
  max-width: 100px;
  width: 10%;
}

.modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditCodeList-modal thead tr th:nth-child(1) {
  min-width: 65px;
  max-width: 65px;
  width: 7%;
}

.modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditCodeList-modal thead tr th:nth-child(2) {
  width: 17%;
  min-width: 160px;
  max-width: 160px;
}

.modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditCodeList-modal thead tr th:nth-child(3) {
  width: 10%;
  min-width: 90px;
  max-width: 90px;
}

.modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditCodeList-modal thead tr th:nth-child(4) {
  width: 8%;
  min-width: 70px;
  max-width: 70px;
}

.modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditCodeList-modal thead tr th:nth-child(5) {
  width: 18%;
  min-width: 165px;
  max-width: 165px;
}

.modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditCodeList-modal thead tr th:nth-child(6) {
  width: 30%;
  min-width: 285px;
  max-width: 285px;
}

.modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditCodeList-modal tbody tr td {
  min-width: 100px;
  max-width: 100px;
  width: 10%;
}

.modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditCodeList-modal tbody tr td:nth-child(1) {
  min-width: 65px;
  max-width: 65px;
  width: 7%;
}

.modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditCodeList-modal tbody tr td:nth-child(2) {
  width: 17%;
  min-width: 160px;
  max-width: 160px;
}

.modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditCodeList-modal tbody tr td:nth-child(3) {
  width: 10%;
  min-width: 90px;
  max-width: 90px;
}

.modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditCodeList-modal tbody tr td:nth-child(4) {
  width: 8%;
  min-width: 70px;
  max-width: 70px;
}

.modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditCodeList-modal tbody tr td:nth-child(5) {
  width: 18%;
  min-width: 165px;
  max-width: 165px;
}

.modal-HistoryofCodeList .modal-content .modal-body .box-tableHistoryAudit .tablehistoryAuditCodeList-modal tbody tr td:nth-child(6) {
  width: 30%;
  min-width: 285px;
  max-width: 285px;
}

.modal-HistoryOfEcs .modal-dialog {
  max-width: 1400px;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc thead tr th {
  min-width: 100px;
  max-width: 100px;
  width: 10%;
  font-size: 12px;
  padding: 8px 2px;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc thead tr th:nth-child(1) {
  min-width: 50px;
  max-width: 50px;
  width: 4%;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc thead tr th:nth-child(2) {
  min-width: 100px;
  max-width: 100px;
  width: 8%;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc thead tr th:nth-child(3) {
  min-width: 100px;
  max-width: 100px;
  width: 8%;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc thead tr th:nth-child(4) {
  min-width: 60px;
  max-width: 60px;
  width: 5%;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc thead tr th:nth-child(5) {
  min-width: 180px;
  max-width: 180px;
  width: 13%;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc thead tr th:nth-child(6) {
  min-width: 80px;
  max-width: 80px;
  width: 6%;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc thead tr th:nth-child(7) {
  min-width: 80px;
  max-width: 80px;
  width: 6%;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc thead tr th:nth-child(8) {
  min-width: 80px;
  max-width: 80px;
  width: 6%;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc thead tr th:nth-child(9) {
  min-width: 80px;
  max-width: 80px;
  width: 6%;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc thead tr th:nth-child(10) {
  min-width: 100px;
  max-width: 100px;
  width: 7%;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc thead tr th:nth-child(11) {
  min-width: 120px;
  max-width: 120px;
  width: 10%;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc thead tr th:nth-child(12) {
  min-width: 50px;
  max-width: 50px;
  width: 4%;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc thead tr th:nth-child(13) {
  min-width: 80px;
  max-width: 80px;
  width: 6%;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc thead tr th:nth-child(14) {
  min-width: 120px;
  max-width: 120px;
  width: 10%;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc tbody tr td {
  min-width: 100px;
  max-width: 100px;
  width: 10%;
  font-size: 12px;
  padding: 8px 2px;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc tbody tr td:nth-child(1) {
  min-width: 50px;
  max-width: 50px;
  width: 4%;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc tbody tr td:nth-child(2) {
  min-width: 100px;
  max-width: 100px;
  width: 8%;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc tbody tr td:nth-child(3) {
  min-width: 100px;
  max-width: 100px;
  width: 8%;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc tbody tr td:nth-child(4) {
  min-width: 60px;
  max-width: 60px;
  width: 5%;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc tbody tr td:nth-child(5) {
  min-width: 180px;
  max-width: 180px;
  width: 13%;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc tbody tr td:nth-child(6) {
  min-width: 80px;
  max-width: 80px;
  width: 6%;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc tbody tr td:nth-child(7) {
  min-width: 80px;
  max-width: 80px;
  width: 6%;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc tbody tr td:nth-child(8) {
  min-width: 80px;
  max-width: 80px;
  width: 6%;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc tbody tr td:nth-child(9) {
  min-width: 80px;
  max-width: 80px;
  width: 6%;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc tbody tr td:nth-child(10) {
  min-width: 100px;
  max-width: 100px;
  width: 7%;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc tbody tr td:nth-child(11) {
  min-width: 120px;
  max-width: 120px;
  width: 10%;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc tbody tr td:nth-child(12) {
  min-width: 50px;
  max-width: 50px;
  width: 4%;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc tbody tr td:nth-child(13) {
  min-width: 80px;
  max-width: 80px;
  width: 6%;
}

.modal-HistoryOfEcs .modal-content .modal-body .box-tableHistoryAudit .table-historyofesc tbody tr td:nth-child(14) {
  min-width: 120px;
  max-width: 120px;
  width: 10%;
}

.modal-importCRF .modal-dialog, .modal-importCodeList .modal-dialog, .modal-importecs .modal-dialog {
  max-width: 550px;
}

.modal-importCRF .modal-content .modal-body, .modal-importCodeList .modal-content .modal-body, .modal-importecs .modal-content .modal-body {
  padding-top: 16px;
  padding-bottom: 16px;
}

.modal-importCRF .modal-content .modal-body .grpform-input .txt-label, .modal-importCodeList .modal-content .modal-body .grpform-input .txt-label, .modal-importecs .modal-content .modal-body .grpform-input .txt-label {
  width: 60px;
  margin-right: 10px;
  line-height: 30px;
}

.modal-importCRF .modal-content .modal-body .grpform-input .txt-required, .modal-importCodeList .modal-content .modal-body .grpform-input .txt-required, .modal-importecs .modal-content .modal-body .grpform-input .txt-required {
  width: 50px;
  margin-right: 10px;
  line-height: 26px;
}

.modal-importCRF .modal-content .modal-body .grpform-input .frm-input, .modal-importCodeList .modal-content .modal-body .grpform-input .frm-input, .modal-importecs .modal-content .modal-body .grpform-input .frm-input {
  width: calc(100% - 60px - 10px - 50px - 10px - 65px - 10px);
}

.modal-importCRF .modal-content .modal-body .grpform-input .frm-btn, .modal-importCodeList .modal-content .modal-body .grpform-input .frm-btn, .modal-importecs .modal-content .modal-body .grpform-input .frm-btn {
  width: 65px;
  margin-left: 10px;
}

.modal-importCRF .modal-content .modal-body .grp-buttonfooter .btn, .modal-importCodeList .modal-content .modal-body .grp-buttonfooter .btn, .modal-importecs .modal-content .modal-body .grp-buttonfooter .btn {
  min-width: 110px;
}

.modal-itemcreatcode .modal-dialog, .modal-itemeditcode .modal-dialog {
  max-width: 900px;
}

.modal-itemcreatcode .modal-content .modal-body, .modal-itemeditcode .modal-content .modal-body {
  padding: 16px;
}

.modal-itemcreatcode .modal-content .modal-body .grp-forminputmodal .grpform-input .txt-label, .modal-itemeditcode .modal-content .modal-body .grp-forminputmodal .grpform-input .txt-label {
  width: 40px;
}

.modal-itemcreatcode .modal-content .modal-body .grp-forminputmodal .grpform-input .txt-required, .modal-itemeditcode .modal-content .modal-body .grp-forminputmodal .grpform-input .txt-required {
  margin-right: 10px;
}

.modal-itemcreatcode .modal-content .modal-body .grp-forminputmodal .grpform-input .frm-input, .modal-itemeditcode .modal-content .modal-body .grp-forminputmodal .grpform-input .frm-input {
  width: 60%;
}

.modal-itemcreatcode .modal-content .modal-body .box-tablemodal .tablefixedhead .box-tbodytable, .modal-itemeditcode .modal-content .modal-body .box-tablemodal .tablefixedhead .box-tbodytable {
  max-height: 300px;
}

.modal-itemcreatcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal thead tr th, .modal-itemeditcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal thead tr th {
  background: #445466;
  color: #fff;
  padding: 4px;
  min-width: 95px;
  max-width: 95px;
  width: 10%;
}

.modal-itemcreatcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal thead tr th:nth-child(1), .modal-itemeditcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal thead tr th:nth-child(1) {
  min-width: 90px;
  max-width: 90px;
  width: 14%;
}

.modal-itemcreatcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal thead tr th:nth-child(2), .modal-itemeditcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal thead tr th:nth-child(2) {
  min-width: 200px;
  max-width: 200px;
  width: 21%;
}

.modal-itemcreatcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal thead tr th:nth-child(3), .modal-itemeditcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal thead tr th:nth-child(3) {
  min-width: 300px;
  max-width: 300px;
  width: 25%;
}

.modal-itemcreatcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal thead tr th:nth-child(4), .modal-itemeditcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal thead tr th:nth-child(4) {
  min-width: 80px;
  max-width: 80px;
  width: 14%;
}

.modal-itemcreatcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal thead tr th:nth-child(5), .modal-itemeditcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal thead tr th:nth-child(5) {
  min-width: 80px;
  max-width: 80px;
  width: 14%;
}

.modal-itemcreatcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal thead tr th:nth-child(6), .modal-itemeditcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal thead tr th:nth-child(6) {
  min-width: 80px;
  max-width: 80px;
  width: 14%;
}

.modal-itemcreatcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal tbody tr td, .modal-itemeditcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal tbody tr td {
  border: 0;
  border-bottom: 1px solid #ddd;
  text-align: center;
}

.modal-itemcreatcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal tbody tr td:first-child, .modal-itemeditcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal tbody tr td:first-child {
  border-left: 1px solid #ddd;
}

.modal-itemcreatcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal tbody tr td:last-child, .modal-itemeditcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal tbody tr td:last-child {
  border-right: 1px solid #ddd;
}

.modal-itemcreatcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal tbody tr td:nth-child(1), .modal-itemeditcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal tbody tr td:nth-child(1) {
  min-width: 90px;
  max-width: 90px;
  width: 14%;
}

.modal-itemcreatcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal tbody tr td:nth-child(2), .modal-itemeditcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal tbody tr td:nth-child(2) {
  min-width: 200px;
  max-width: 200px;
  width: 21%;
}

.modal-itemcreatcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal tbody tr td:nth-child(3), .modal-itemeditcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal tbody tr td:nth-child(3) {
  min-width: 300px;
  max-width: 300px;
  width: 25%;
}

.modal-itemcreatcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal tbody tr td:nth-child(4), .modal-itemeditcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal tbody tr td:nth-child(4) {
  min-width: 80px;
  max-width: 80px;
  width: 14%;
}

.modal-itemcreatcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal tbody tr td:nth-child(5), .modal-itemeditcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal tbody tr td:nth-child(5) {
  min-width: 80px;
  max-width: 80px;
  width: 14%;
}

.modal-itemcreatcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal tbody tr td:nth-child(6), .modal-itemeditcode .modal-content .modal-body .box-tablemodal .tblcreatecode-modal tbody tr td:nth-child(6) {
  min-width: 80px;
  max-width: 80px;
  width: 14%;
}

.modal-itemcreatversion .modal-dialog {
  max-width: 700px;
}

.modal-itemcreatversion .modal-content .modal-body {
  padding: 16px;
}

.modal-itemcreatversion .modal-content .modal-body .grp-forminputmodal .grpform-input .txt-label {
  width: 150px;
}

.modal-itemcreatversion .modal-content .modal-body .grp-forminputmodal .grpform-input .frm-input {
  width: calc(100% - 150px - 60px);
}

.modal-itemcreatversion .modal-content .modal-body .grp-forminputmodal .grpform-input .frm-input .groupseclect-checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal-itemcreatversion .modal-content .modal-body .grp-forminputmodal .grpform-input .frm-input .groupseclect-checkbox .bxselect {
  width: 200px;
  padding-right: 15px;
}

.modal-itemcreatversion .modal-content .modal-body .bx-btnmodal {
  margin-top: 24px;
}

.modal-itemcreatversion .modal-content .modal-body .bx-btnmodal .btn {
  min-width: 110px;
}

.modal-itemeditcode .modal-content .modal-body .grp-forminputmodal .grpform-input .txt-label {
  width: 60px;
}

.tablepreview1-modal thead tr th {
  text-align: left;
  background: #EDF4FB;
}

.tablepreview1-modal tbody tr th {
  background: #F4F4F4;
  font-weight: normal;
  text-align: left;
}

.tablepreview1-modal tbody tr td .gr-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.tablepreview1-modal tbody tr td .gr-flex .flex-left {
  width: 40%;
}

.tablepreview1-modal tbody tr td .gr-flex .flex-left .form-control {
  width: 200px;
}

.tablepreview1-modal tbody tr td .gr-flex .flex-right .form-control {
  width: 100px;
}

.tablepreview2-modal thead tr:nth-child(1) th {
  text-align: left;
  background: #EDF4FB;
}

.tablepreview2-modal tbody tr td {
  text-align: center;
  width: 33.33333333%;
}

.tablepreview3-modal thead tr:nth-child(1) th {
  text-align: left;
  background: #EDF4FB;
  width: auto;
}

.tablepreview3-modal tbody tr td {
  text-align: center;
}

.box-pageeditor, .box-blockeditor, .box-questioneditor, .box-itemeditor {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 15px 20px 15px;
}

.box-pageeditor .box-contentleft, .box-blockeditor .box-contentleft, .box-questioneditor .box-contentleft, .box-itemeditor .box-contentleft {
  padding-right: 20px;
  width: 50%;
  border-right: 1px solid #707070;
}

.box-pageeditor .box-contentleft .grpform-input, .box-blockeditor .box-contentleft .grpform-input, .box-questioneditor .box-contentleft .grpform-input, .box-itemeditor .box-contentleft .grpform-input {
  margin-bottom: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.box-pageeditor .box-contentleft .grpform-input .txt-label, .box-blockeditor .box-contentleft .grpform-input .txt-label, .box-questioneditor .box-contentleft .grpform-input .txt-label, .box-itemeditor .box-contentleft .grpform-input .txt-label {
  width: 75px;
  margin-right: 10px;
}

.box-pageeditor .box-contentleft .grpform-input .frm-input, .box-blockeditor .box-contentleft .grpform-input .frm-input, .box-questioneditor .box-contentleft .grpform-input .frm-input, .box-itemeditor .box-contentleft .grpform-input .frm-input {
  width: 140px;
  margin-right: 20px;
}

.box-pageeditor .box-contentleft .grpform-input .frm-checkbox, .box-blockeditor .box-contentleft .grpform-input .frm-checkbox, .box-questioneditor .box-contentleft .grpform-input .frm-checkbox, .box-itemeditor .box-contentleft .grpform-input .frm-checkbox {
  width: calc(100% - 75px - 10px - 140px - 20px);
}

.box-pageeditor .box-contentleft .menueditor a, .box-blockeditor .box-contentleft .menueditor a, .box-questioneditor .box-contentleft .menueditor a, .box-itemeditor .box-contentleft .menueditor a {
  display: block;
}

.box-pageeditor .box-contentleft .menueditor a .groupiconeditor, .box-blockeditor .box-contentleft .menueditor a .groupiconeditor, .box-questioneditor .box-contentleft .menueditor a .groupiconeditor, .box-itemeditor .box-contentleft .menueditor a .groupiconeditor {
  display: none;
  min-width: 110px;
}

.box-pageeditor .box-contentleft .menueditor a:hover, .box-blockeditor .box-contentleft .menueditor a:hover, .box-questioneditor .box-contentleft .menueditor a:hover, .box-itemeditor .box-contentleft .menueditor a:hover, .box-pageeditor .box-contentleft .menueditor a.active, .box-blockeditor .box-contentleft .menueditor a.active, .box-questioneditor .box-contentleft .menueditor a.active, .box-itemeditor .box-contentleft .menueditor a.active {
  background: #f9f9f9;
}

.box-pageeditor .box-contentleft .menueditor a:hover .groupiconeditor, 
.box-blockeditor .box-contentleft .menueditor a:hover .groupiconeditor, 
.box-questioneditor .box-contentleft .menueditor a:hover .groupiconeditor, 
.box-itemeditor .box-contentleft .menueditor a:hover .groupiconeditor {
  display: inline-block;
}

.box-pageeditor .box-contentleft .menueditor a:hover.text-throught .groupiconeditor, .box-blockeditor .box-contentleft .menueditor a:hover.text-throught .groupiconeditor, .box-questioneditor .box-contentleft .menueditor a:hover.text-throught .groupiconeditor, .box-itemeditor .box-contentleft .menueditor a:hover.text-throught .groupiconeditor, .box-pageeditor .box-contentleft .menueditor a.active.text-throught .groupiconeditor, .box-blockeditor .box-contentleft .menueditor a.active.text-throught .groupiconeditor, .box-questioneditor .box-contentleft .menueditor a.active.text-throught .groupiconeditor, .box-itemeditor .box-contentleft .menueditor a.active.text-throught .groupiconeditor {
  display: none;
}

.box-pageeditor .box-contentleft .menueditor .list-menueditor .item-menueditor, .box-blockeditor .box-contentleft .menueditor .list-menueditor .item-menueditor, .box-questioneditor .box-contentleft .menueditor .list-menueditor .item-menueditor, .box-itemeditor .box-contentleft .menueditor .list-menueditor .item-menueditor {
  padding: 5px 0;
  position: relative;
  padding-left: 18px;
}

.box-pageeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .icon-arrow, .box-blockeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .icon-arrow, .box-questioneditor .box-contentleft .menueditor .list-menueditor .item-menueditor .icon-arrow, .box-itemeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .icon-arrow {
  display: inline-block;
  background: url("../images/ic_arrowmenu.png") no-repeat 0 0;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 6px;
  left: 0;
  font-size: 12px;
  cursor: pointer;
}

.box-pageeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .link-menueditor, .box-blockeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .link-menueditor, .box-questioneditor .box-contentleft .menueditor .list-menueditor .item-menueditor .link-menueditor, .box-itemeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .link-menueditor {
  color: #333;
  position: relative;
  display: block;
}

/* .box-pageeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .link-menueditor.active + .list-submenueditor, .box-blockeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .link-menueditor.active + .list-submenueditor, .box-questioneditor .box-contentleft .menueditor .list-menueditor .item-menueditor .link-menueditor.active + .list-submenueditor, .box-itemeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .link-menueditor.active + .list-submenueditor {
  display: block;
} */

.box-pageeditor .box-contentleft .menueditor .list-menueditor .item-menueditor.currentshow .list-submenueditor, .box-blockeditor .box-contentleft .menueditor .list-menueditor .item-menueditor.currentshow .list-submenueditor, .box-questioneditor .box-contentleft .menueditor .list-menueditor .item-menueditor.currentshow .list-submenueditor, .box-itemeditor .box-contentleft .menueditor .list-menueditor .item-menueditor.currentshow .list-submenueditor {
  display: block;
}

.box-pageeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor, .box-blockeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor, .box-questioneditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor, .box-itemeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor {
  position: relative;
  display: block;
}

.box-pageeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .icon-subarrow, .box-blockeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .icon-subarrow, .box-questioneditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .icon-subarrow, .box-itemeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .icon-subarrow {
  left: 5px;
}

.box-pageeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor, .box-blockeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor, .box-questioneditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor, .box-itemeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor {
  padding: 5px 0;
  padding-left: 24px;
  position: relative;
}

.box-pageeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .link-submenueditor, .box-blockeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .link-submenueditor, .box-questioneditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .link-submenueditor, .box-itemeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .link-submenueditor {
  color: #333;
}

.box-pageeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .list-minusubmenueditor .item-minisub, .box-blockeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .list-minusubmenueditor .item-minisub, .box-questioneditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .list-minusubmenueditor .item-minisub, .box-itemeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .list-minusubmenueditor .item-minisub {
  padding: 5px 0;
  position: relative;
  padding-left: 25px;
}

.box-pageeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .list-minusubmenueditor .item-minisub .icon-minisubarrow, .box-blockeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .list-minusubmenueditor .item-minisub .icon-minisubarrow, .box-questioneditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .list-minusubmenueditor .item-minisub .icon-minisubarrow, .box-itemeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .list-minusubmenueditor .item-minisub .icon-minisubarrow {
  left: 5px;
}

.box-pageeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .list-minusubmenueditor .item-minisub .link-minisubeditor, .box-blockeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .list-minusubmenueditor .item-minisub .link-minisubeditor, .box-questioneditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .list-minusubmenueditor .item-minisub .link-minisubeditor, .box-itemeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .list-minusubmenueditor .item-minisub .link-minisubeditor {
  color: #333;
  position: relative;
}

.box-pageeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .list-minusubmenueditor .item-minisub .list-minixsubeditor, .box-blockeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .list-minusubmenueditor .item-minisub .list-minixsubeditor, .box-questioneditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .list-minusubmenueditor .item-minisub .list-minixsubeditor, .box-itemeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .list-minusubmenueditor .item-minisub .list-minixsubeditor {
  padding-left: 25px;
}

.box-pageeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .list-minusubmenueditor .item-minisub .list-minixsubeditor .item-minixsub, .box-blockeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .list-minusubmenueditor .item-minisub .list-minixsubeditor .item-minixsub, .box-questioneditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .list-minusubmenueditor .item-minisub .list-minixsubeditor .item-minixsub, .box-itemeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .list-minusubmenueditor .item-minisub .list-minixsubeditor .item-minixsub {
  padding-top: 5px;
}

.box-pageeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .list-minusubmenueditor .item-minisub .list-minixsubeditor .item-minixsub .link-minixsub, .box-blockeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .list-minusubmenueditor .item-minisub .list-minixsubeditor .item-minixsub .link-minixsub, .box-questioneditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .list-minusubmenueditor .item-minisub .list-minixsubeditor .item-minixsub .link-minixsub, .box-itemeditor .box-contentleft .menueditor .list-menueditor .item-menueditor .list-submenueditor .item-submenueditor .list-minusubmenueditor .item-minisub .list-minixsubeditor .item-minixsub .link-minixsub {
  color: #333;
}

.box-pageeditor .box-contentright, .box-blockeditor .box-contentright, .box-questioneditor .box-contentright, .box-itemeditor .box-contentright {
  width: 50%;
  padding-left: 20px;
}

.box-pageeditor .box-contentright .heading-1, .box-blockeditor .box-contentright .heading-1, .box-questioneditor .box-contentright .heading-1, .box-itemeditor .box-contentright .heading-1 {
  padding-bottom: 20px;
}

.box-pageeditor .box-contentright .group-formeditor .grpform-input, .box-blockeditor .box-contentright .group-formeditor .grpform-input, .box-questioneditor .box-contentright .group-formeditor .grpform-input, .box-itemeditor .box-contentright .group-formeditor .grpform-input {
  margin-bottom: 10px;
}

.box-pageeditor .box-contentright .group-formeditor .grpform-input .txt-label, .box-blockeditor .box-contentright .group-formeditor .grpform-input .txt-label, .box-questioneditor .box-contentright .group-formeditor .grpform-input .txt-label, .box-itemeditor .box-contentright .group-formeditor .grpform-input .txt-label {
  line-height: 30px;
  width: 130px;
}

.box-pageeditor .box-contentright .group-formeditor .grpform-input .txt-required, .box-blockeditor .box-contentright .group-formeditor .grpform-input .txt-required, .box-questioneditor .box-contentright .group-formeditor .grpform-input .txt-required, .box-itemeditor .box-contentright .group-formeditor .grpform-input .txt-required {
  line-height: 30px;
  margin-right: 10px;
  width: 75px;
}

.box-pageeditor .box-contentright .group-formeditor .grpform-input .frm-input, .box-blockeditor .box-contentright .group-formeditor .grpform-input .frm-input, .box-questioneditor .box-contentright .group-formeditor .grpform-input .frm-input, .box-itemeditor .box-contentright .group-formeditor .grpform-input .frm-input {
  width: calc(100% - 110px - 50px - 10px);
}

.box-pageeditor .box-contentright .group-formeditor .grpform-input.grpform-area .txt-label, .box-blockeditor .box-contentright .group-formeditor .grpform-input.grpform-area .txt-label, .box-questioneditor .box-contentright .group-formeditor .grpform-input.grpform-area .txt-label, .box-itemeditor .box-contentright .group-formeditor .grpform-input.grpform-area .txt-label {
  margin-top: 27px;
}

.box-pageeditor .box-contentright .group-formeditor .grpform-input.grpform-area .txt-required, .box-blockeditor .box-contentright .group-formeditor .grpform-input.grpform-area .txt-required, .box-questioneditor .box-contentright .group-formeditor .grpform-input.grpform-area .txt-required, .box-itemeditor .box-contentright .group-formeditor .grpform-input.grpform-area .txt-required {
  margin-top: 27px;
}

.box-pageeditor .box-contentright .group-formeditor .grpform-input.grpform-area .frm-input .textlong-1, .box-blockeditor .box-contentright .group-formeditor .grpform-input.grpform-area .frm-input .textlong-1, .box-questioneditor .box-contentright .group-formeditor .grpform-input.grpform-area .frm-input .textlong-1, .box-itemeditor .box-contentright .group-formeditor .grpform-input.grpform-area .frm-input .textlong-1 {
  height: 85px;
}

.box-itemeditor .group-formeditor .grpform-input .frm-input .dropdown .dropdown-menu .dropdown-item {
  padding: 5px;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.box-itemeditor .group-formeditor .grpform-input .frm-input .dropdown .dropdown-menu .dropdown-item span {
  width: 50%;
}

.box-itemeditor .group-formeditor .grpform-input .frm-input .dropdown .dropdown-menu .dropdown-item:nth-child(even) {
  background: #f8f9fa;
}

.box-CRFGroup {
  padding: 16px;
}

.box-CRFGroup .frm-checkbox {
  padding-bottom: 16px;
}

.box-CRFGroup .table-frcgroup thead tr th {
  width: 6%;
  min-width: 100px;
  max-width: 100px;
}

.box-CRFGroup .table-frcgroup thead tr th:nth-child(1) {
  width: 5%;
  min-width: 70px;
  max-width: 70px;
}

.box-CRFGroup .table-frcgroup thead tr th:nth-child(2) {
  width: 18%;
  min-width: 210px;
  max-width: 210px;
}

.box-CRFGroup .table-frcgroup thead tr th:nth-child(3) {
  width: 25%;
  min-width: 290px;
  max-width: 290px;
}

.box-CRFGroup .table-frcgroup thead tr th:nth-child(4) {
  width: 18%;
  min-width: 210px;
  max-width: 210px;
}

.box-CRFGroup .table-frcgroup thead tr th:nth-child(5) {
  width: 4%;
  min-width: 60px;
  max-width: 60px;
}

.box-CRFGroup .table-frcgroup thead tr th:nth-child(6) {
  width: 4%;
  min-width: 60px;
  max-width: 60px;
}

.box-CRFGroup .table-frcgroup tbody tr td {
  text-align: center;
  width: 6%;
  min-width: 100px;
  max-width: 100px;
}

.box-CRFGroup .table-frcgroup tbody tr td:nth-child(1) {
  width: 5%;
  min-width: 70px;
  max-width: 70px;
}

.box-CRFGroup .table-frcgroup tbody tr td:nth-child(2) {
  width: 18%;
  min-width: 210px;
  max-width: 210px;
}

.box-CRFGroup .table-frcgroup tbody tr td:nth-child(3) {
  width: 25%;
  min-width: 290px;
  max-width: 290px;
}

.box-CRFGroup .table-frcgroup tbody tr td:nth-child(4) {
  width: 18%;
  min-width: 210px;
  max-width: 210px;
}

.box-CRFGroup .table-frcgroup tbody tr td:nth-child(5) {
  width: 4%;
  min-width: 60px;
  max-width: 60px;
}

.box-CRFGroup .table-frcgroup tbody tr td:nth-child(6) {
  width: 4%;
  min-width: 60px;
  max-width: 60px;
}

.box-CRFGroup .table-frcgroup tbody tr td .form-control.form-controlerror {
  border: 1px solid #FF0000;
  color: #999;
}

.box-CRFGroup .box-tablecrfGroup .tablefixedhead .box-tbodytable {
  max-height: calc(100vh - 255px);
}

.box-CodeList {
  padding: 16px;
}

.box-CodeList .box-groupformcodelist .rowformcodelist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -16px;
}

.box-CodeList .box-groupformcodelist .rowformcodelist .colflex {
  padding: 0 16px;
}

.box-CodeList .box-groupformcodelist .rowformcodelist .colflex .frm-grpbtn .btn {
  min-width: 110px;
}

.box-CodeList .box-groupformcodelist .rowformcodelist .colflex.flexinput-1 {
  width: 272px;
}

.box-CodeList .box-groupformcodelist .rowformcodelist .colflex.flexinput-1 .grpform-input .txt-label {
  width: 30px;
  margin-right: 10px;
}

.box-CodeList .box-groupformcodelist .rowformcodelist .colflex.flexinput-1 .grpform-input .frm-input {
  width: 200px;
}

.box-CodeList .box-groupformcodelist .rowformcodelist .colflex.flexinput-2 {
  width: 288px;
}

.box-CodeList .box-groupformcodelist .rowformcodelist .colflex.flexinput-2 .grpform-input .txt-label {
  width: 46px;
  margin-right: 10px;
}

.box-CodeList .box-groupformcodelist .rowformcodelist .colflex.flexinput-2 .grpform-input .frm-input {
  width: 200px;
}

.box-CodeList .box-groupformcodelist .rowformcodelist .colflex.flexinput-3 {
  width: 265px;
}

.box-CodeList .box-tablecodelist .grpform-input {
  margin-bottom: 8px;
}

.box-CodeList .box-tablecodelist .grpform-input .frm-input {
  width: 100px;
}

.box-CodeList .box-tablecodelist .grpform-input .txt-unit {
  line-height: 30px;
  padding-left: 10px;
  width: calc(100% - 100px);
}

.box-CodeList .box-tablecodelist .tablefixedhead .table-codelist thead tr th {
  padding: 4px 3px;
  min-width: 100px;
  max-width: 100px;
  width: 10%;
}

.box-CodeList .box-tablecodelist .tablefixedhead .table-codelist thead tr th:nth-child(1) {
  min-width: 60px;
  max-width: 60px;
  width: 7%;
}

.box-CodeList .box-tablecodelist .tablefixedhead .table-codelist thead tr th:nth-child(2) {
  min-width: 150px;
  max-width: 150px;
  width: 15%;
}

.box-CodeList .box-tablecodelist .tablefixedhead .table-codelist thead tr th:nth-child(3) {
  min-width: 200px;
  max-width: 200px;
  width: 20%;
}

.box-CodeList .box-tablecodelist .tablefixedhead .table-codelist thead tr th:nth-child(4) {
  min-width: 250px;
  max-width: 250px;
  width: 25%;
}

.box-CodeList .box-tablecodelist .tablefixedhead .table-codelist thead tr th:nth-child(5) {
  min-width: 100px;
  max-width: 100px;
  width: 11%;
}

.box-CodeList .box-tablecodelist .tablefixedhead .table-codelist thead tr th:nth-child(6) {
  min-width: 100px;
  max-width: 100px;
  width: 7%;
}

.box-CodeList .box-tablecodelist .tablefixedhead .table-codelist thead tr th:nth-child(7) {
  min-width: 70px;
  max-width: 70px;
  width: 6%;
}

.box-CodeList .box-tablecodelist .tablefixedhead .table-codelist tbody tr td {
  padding: 4px 3px;
  min-width: 100px;
  max-width: 100px;
  width: 10%;
}

.box-CodeList .box-tablecodelist .tablefixedhead .table-codelist tbody tr td:nth-child(1) {
  min-width: 60px;
  max-width: 60px;
  width: 7%;
}

.box-CodeList .box-tablecodelist .tablefixedhead .table-codelist tbody tr td:nth-child(2) {
  min-width: 150px;
  max-width: 150px;
  width: 15%;
}

.box-CodeList .box-tablecodelist .tablefixedhead .table-codelist tbody tr td:nth-child(3) {
  min-width: 200px;
  max-width: 200px;
  width: 20%;
}

.box-CodeList .box-tablecodelist .tablefixedhead .table-codelist tbody tr td:nth-child(4) {
  min-width: 250px;
  max-width: 250px;
  width: 25%;
}

.box-CodeList .box-tablecodelist .tablefixedhead .table-codelist tbody tr td:nth-child(5) {
  min-width: 100px;
  max-width: 100px;
  width: 11%;
}

.box-CodeList .box-tablecodelist .tablefixedhead .table-codelist tbody tr td:nth-child(6) {
  min-width: 100px;
  max-width: 100px;
  width: 7%;
}

.box-CodeList .box-tablecodelist .tablefixedhead .table-codelist tbody tr td:nth-child(7) {
  min-width: 70px;
  max-width: 70px;
  width: 6%;
}

.box-CodeList .box-tablecodelist .tablefixedhead .box-theadtable {
  overflow: hidden;
}

.box-CodeList .box-tablecodelist .tablefixedhead .box-tbodytable {
  max-height: calc(100vh - 375px);
  overflow: auto;
}

.box-listEsc {
  padding: 8px 16px 16px 16px;
}

.box-listEsc .box-groupformlistesc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px;
}

.box-listEsc .box-groupformlistesc .colflex {
  padding: 8px 16px;
}

.box-listEsc .box-groupformlistesc .colflex .grpform-input .txt-label {
  width: 60px;
  margin-right: 10px;
}

.box-listEsc .box-groupformlistesc .colflex .grpform-input .frm-input {
  width: 250px;
}

.box-listEsc .box-groupformlistesc .colflex .frm-grpbtn .btn {
  min-width: 110px;
}

.box-listEsc .box-groupformlistesc .colflex.flexinput-1 {
  width: 352px;
}

.box-listEsc .box-groupformlistesc .colflex.flexinput-2 {
  width: 282px;
}

.box-listEsc .box-groupformlistesc .colflex.flexinput-2 .grpform-input .txt-label {
  width: 40px;
  margin-right: 10px;
}

.box-listEsc .box-groupformlistesc .colflex.flexinput-2 .grpform-input .frm-input {
  width: 200px;
}

.box-listEsc .box-groupformlistesc .colflex.flexinput-3 {
  width: 202px;
}

.box-listEsc .box-groupformlistesc .colflex.flexinput-3 .grpform-input .frm-input {
  width: 100px;
}

.box-listEsc .box-groupformlistesc .colflex.flexinput-4 {
  width: 210px;
}

.box-listEsc .box-groupformlistesc .colflex.flexinput-5 {
  width: 265px;
}

.box-listEsc .box-tablelistesc .grpform-input {
  margin-bottom: 8px;
}

.box-listEsc .box-tablelistesc .grpform-input .frm-input {
  width: 100px;
}

.box-listEsc .box-tablelistesc .grpform-input .txt-unit {
  line-height: 30px;
  padding-left: 10px;
  width: calc(100% - 100px);
}

.box-listEsc .box-tablelistesc .tablelistesc thead tr th {
  padding: 8px 3px;
  min-width: 100px;
  max-width: 100px;
  width: 7%;
}

.box-listEsc .box-tablelistesc .tablelistesc thead tr th:nth-child(1) {
  width: 5%;
  min-width: 60px;
  max-width: 60px;
}

.box-listEsc .box-tablelistesc .tablelistesc thead tr th:nth-child(2) {
  width: 15%;
  min-width: 140px;
  max-width: 140px;
}

.box-listEsc .box-tablelistesc .tablelistesc thead tr th:nth-child(3) {
  width: 12%;
  min-width: 110px;
  max-width: 110px;
}

.box-listEsc .box-tablelistesc .tablelistesc thead tr th:nth-child(4) {
  width: 10%;
  min-width: 100px;
  max-width: 100px;
}

.box-listEsc .box-tablelistesc .tablelistesc thead tr th:nth-child(5) {
  width: auto;
  min-width: 250px;
  max-width: 250px;
}

.box-listEsc .box-tablelistesc .tablelistesc thead tr th:nth-child(6) {
  width: 8%;
  min-width: 90px;
  max-width: 90px;
}

.box-listEsc .box-tablelistesc .tablelistesc thead tr th:nth-child(7) {
  width: 6%;
  min-width: 60px;
  max-width: 60px;
}

.box-listEsc .box-tablelistesc .tablelistesc thead tr th:nth-child(8) {
  width: 6%;
  min-width: 60px;
  max-width: 60px;
}

.box-listEsc .box-tablelistesc .tablelistesc tbody tr td {
  padding: 4px 3px;
  font-size: 14px;
  min-width: 100px;
  max-width: 100px;
  width: 7%;
}

.box-listEsc .box-tablelistesc .tablelistesc tbody tr td:nth-child(1) {
  width: 5%;
  min-width: 60px;
  max-width: 60px;
}

.box-listEsc .box-tablelistesc .tablelistesc tbody tr td:nth-child(2) {
  width: 15%;
  min-width: 140px;
  max-width: 140px;
}

.box-listEsc .box-tablelistesc .tablelistesc tbody tr td:nth-child(3) {
  width: 12%;
  min-width: 110px;
  max-width: 110px;
}

.box-listEsc .box-tablelistesc .tablelistesc tbody tr td:nth-child(4) {
  width: 10%;
  min-width: 100px;
  max-width: 100px;
}

.box-listEsc .box-tablelistesc .tablelistesc tbody tr td:nth-child(5) {
  width: auto;
  min-width: 250px;
  max-width: 250px;
}

.box-listEsc .box-tablelistesc .tablelistesc tbody tr td:nth-child(6) {
  width: 8%;
  min-width: 90px;
  max-width: 90px;
}

.box-listEsc .box-tablelistesc .tablelistesc tbody tr td:nth-child(7) {
  width: 6%;
  min-width: 60px;
  max-width: 60px;
}

.box-listEsc .box-tablelistesc .tablelistesc tbody tr td:nth-child(8) {
  width: 6%;
  min-width: 60px;
  max-width: 60px;
}

.box-listEsc .box-tablelistesc .tablefixedhead .box-tbodytable {
  max-height: calc(100vh - 380px);
}

.box-CreateEsc, .box-EditEsc {
  padding: 16px;
}

.box-CreateEsc .box-groupformcreateesc .grpform-input .txt-label, .box-EditEsc .box-groupformcreateesc .grpform-input .txt-label {
  width: 110px;
}

.box-CreateEsc .box-groupformcreateesc .grpform-input.grpform-input-1 .frm-input .form-control, .box-EditEsc .box-groupformcreateesc .grpform-input.grpform-input-1 .frm-input .form-control, .box-CreateEsc .box-groupformcreateesc .grpform-input.grpform-input-2 .frm-input .form-control, .box-EditEsc .box-groupformcreateesc .grpform-input.grpform-input-2 .frm-input .form-control, .box-CreateEsc .box-groupformcreateesc .grpform-input.grpform-input-3 .frm-input .form-control, .box-EditEsc .box-groupformcreateesc .grpform-input.grpform-input-3 .frm-input .form-control {
  width: 260px;
}

.box-CreateEsc .box-groupformcreateesc .grpform-input.grpform-input-5 .frm-input .form-control, .box-EditEsc .box-groupformcreateesc .grpform-input.grpform-input-5 .frm-input .form-control, .box-CreateEsc .box-groupformcreateesc .grpform-input.grpform-input-6 .frm-input .form-control, .box-EditEsc .box-groupformcreateesc .grpform-input.grpform-input-6 .frm-input .form-control, .box-CreateEsc .box-groupformcreateesc .grpform-input.grpform-input-7 .frm-input .form-control, .box-EditEsc .box-groupformcreateesc .grpform-input.grpform-input-7 .frm-input .form-control, .box-CreateEsc .box-groupformcreateesc .grpform-input.grpform-input-8 .frm-input .form-control, .box-EditEsc .box-groupformcreateesc .grpform-input.grpform-input-8 .frm-input .form-control {
  width: 400px;
}

.box-CreateEsc .box-groupformcreateesc .grpform-input.grpform-area .frm-input .form-control, .box-EditEsc .box-groupformcreateesc .grpform-input.grpform-area .frm-input .form-control {
  width: 750px;
}

.box-listVersion .box-tablelistversion .grpform-input {
  margin-bottom: 8px;
}

.box-listVersion .box-tablelistversion .grpform-input .frm-input {
  width: 100px;
}

.box-listVersion .box-tablelistversion .grpform-input .txt-unit {
  line-height: 30px;
  padding-left: 10px;
  width: calc(100% - 100px);
}

.box-listVersion .box-tablelistversion .tablelistversion thead tr th {
  padding: 8px 2px;
  min-width: 100px;
  max-width: 100px;
  width: 7%;
  font-size: 14px;
}

.box-listVersion .box-tablelistversion .tablelistversion thead tr th:nth-child(1) {
  width: 4%;
  min-width: 50px;
  max-width: 50px;
}

.box-listVersion .box-tablelistversion .tablelistversion thead tr th:nth-child(2) {
  width: 10%;
  min-width: 110px;
  max-width: 110px;
}

.box-listVersion .box-tablelistversion .tablelistversion thead tr th:nth-child(3) {
  width: 13%;
  min-width: 132px;
  max-width: 132px;
}

.box-listVersion .box-tablelistversion .tablelistversion thead tr th:nth-child(4) {
  width: 7%;
  min-width: 62px;
  max-width: 62px;
}

.box-listVersion .box-tablelistversion .tablelistversion thead tr th:nth-child(5) {
  width: 10%;
  min-width: 102px;
  max-width: 102px;
}

.box-listVersion .box-tablelistversion .tablelistversion thead tr th:nth-child(6) {
  width: 15%;
  min-width: 135px;
  max-width: 135px;
}

.box-listVersion .box-tablelistversion .tablelistversion thead tr th:nth-child(7) {
  width: 7%;
  min-width: 60px;
  max-width: 60px;
}

.box-listVersion .box-tablelistversion .tablelistversion thead tr th:nth-child(8) {
  width: 9%;
  min-width: 90px;
  max-width: 90px;
}

.box-listVersion .box-tablelistversion .tablelistversion thead tr th:nth-child(9) {
  width: 20%;
  min-width: 200px;
  max-width: 200px;
}

.box-listVersion .box-tablelistversion .tablelistversion tbody tr td {
  padding: 4px 2px;
  font-size: 14px;
  min-width: 100px;
  max-width: 100px;
  width: 7%;
}

.box-listVersion .box-tablelistversion .tablelistversion tbody tr td:nth-child(1) {
  width: 4%;
  min-width: 50px;
  max-width: 50px;
}

.box-listVersion .box-tablelistversion .tablelistversion tbody tr td:nth-child(2) {
  width: 10%;
  min-width: 110px;
  max-width: 110px;
}

.box-listVersion .box-tablelistversion .tablelistversion tbody tr td:nth-child(3) {
  width: 13%;
  min-width: 132px;
  max-width: 132px;
}

.box-listVersion .box-tablelistversion .tablelistversion tbody tr td:nth-child(4) {
  width: 7%;
  min-width: 62px;
  max-width: 62px;
}

.box-listVersion .box-tablelistversion .tablelistversion tbody tr td:nth-child(5) {
  width: 10%;
  min-width: 102px;
  max-width: 102px;
}

.box-listVersion .box-tablelistversion .tablelistversion tbody tr td:nth-child(6) {
  width: 15%;
  min-width: 135px;
  max-width: 135px;
}

.box-listVersion .box-tablelistversion .tablelistversion tbody tr td:nth-child(7) {
  width: 7%;
  min-width: 60px;
  max-width: 60px;
}

.box-listVersion .box-tablelistversion .tablelistversion tbody tr td:nth-child(8) {
  width: 9%;
  min-width: 90px;
  max-width: 90px;
}

.box-listVersion .box-tablelistversion .tablelistversion tbody tr td:nth-child(9) {
  width: 20%;
  min-width: 200px;
  max-width: 200px;
}

.box-listVersion .box-tablelistversion .tablefixedhead .box-tbodytable {
  max-height: calc(100vh - 290px);
}

.box-listusers {
  padding: 8px 16px 16px 16px;
}

.box-listusers .box-groupformlistusers {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px;
}

.box-listusers .box-groupformlistusers .colflex {
  padding: 8px 16px;
}

.box-listusers .box-groupformlistusers .colflex .grpform-input .txt-label {
  width: 60px;
  margin-right: 10px;
}

.box-listusers .box-groupformlistusers .colflex .grpform-input .frm-input {
  width: 250px;
}

.box-listusers .box-groupformlistusers .colflex .frm-grpbtn .btn {
  min-width: 110px;
}

.box-listusers .box-groupformlistusers .colflex.flexinput-1 {
  width: 340px;
}

.box-listusers .box-groupformlistusers .colflex.flexinput-2 {
  width: 340px;
}

.box-listusers .box-groupformlistusers .colflex.flexinput-3 {
  width: 200px;
}

.box-listusers .box-groupformlistusers .colflex.flexinput-5 {
  width: 265px;
}

.box-listusers .box-tablelistusers .grpform-input {
  margin-bottom: 8px;
}

.box-listusers .box-tablelistusers .grpform-input .frm-input {
  width: 100px;
}

.box-listusers .box-tablelistusers .grpform-input .txt-unit {
  line-height: 30px;
  padding-left: 10px;
  width: calc(100% - 100px);
}

.box-listusers .box-tablelistusers .tablelistusers thead tr th {
  padding: 6px 2px;
  min-width: 100px;
  max-width: 100px;
  width: 7%;
  font-size: 13px;
}

.box-listusers .box-tablelistusers .tablelistusers thead tr th:nth-child(1) {
  width: 4%;
  min-width: 50px;
  max-width: 50px;
}

.box-listusers .box-tablelistusers .tablelistusers thead tr th:nth-child(2) {
  width: 15%;
  min-width: 150px;
  max-width: 150px;
}

.box-listusers .box-tablelistusers .tablelistusers thead tr th:nth-child(3) {
  width: 16%;
  min-width: 175px;
  max-width: 175px;
}

.box-listusers .box-tablelistusers .tablelistusers thead tr th:nth-child(4) {
  width: 16%;
  min-width: 175px;
  max-width: 175px;
}

.box-listusers .box-tablelistusers .tablelistusers thead tr th:nth-child(5) {
  width: 10%;
  min-width: 100px;
  max-width: 100px;
}

.box-listusers .box-tablelistusers .tablelistusers thead tr th:nth-child(6) {
  width: 10%;
  min-width: 140px;
  max-width: 140px;
}

.box-listusers .box-tablelistusers .tablelistusers thead tr th:nth-child(7) {
  width: 10%;
  min-width: 140px;
  max-width: 140px;
}

.box-listusers .box-tablelistusers .tablelistusers thead tr th:nth-child(8) {
  width: 4%;
  min-width: 45px;
  max-width: 45px;
}

.box-listusers .box-tablelistusers .tablelistusers thead tr th:nth-child(9) {
  width: 6%;
  min-width: 70px;
  max-width: 70px;
}

.box-listusers .box-tablelistusers .tablelistusers thead tr th:nth-child(10) {
  width: 4%;
  min-width: 45px;
  max-width: 45px;
}

.box-listusers .box-tablelistusers .tablelistusers tbody tr td {
  padding: 4px 2px;
  font-size: 13px;
  min-width: 100px;
  max-width: 100px;
  width: 7%;
}

.box-listusers .box-tablelistusers .tablelistusers tbody tr td:nth-child(1) {
  width: 4%;
  min-width: 50px;
  max-width: 50px;
}

.box-listusers .box-tablelistusers .tablelistusers tbody tr td:nth-child(2) {
  width: 15%;
  min-width: 150px;
  max-width: 150px;
}

.box-listusers .box-tablelistusers .tablelistusers tbody tr td:nth-child(3) {
  width: 16%;
  min-width: 175px;
  max-width: 175px;
}

.box-listusers .box-tablelistusers .tablelistusers tbody tr td:nth-child(4) {
  width: 16%;
  min-width: 175px;
  max-width: 175px;
}

.box-listusers .box-tablelistusers .tablelistusers tbody tr td:nth-child(5) {
  width: 10%;
  min-width: 100px;
  max-width: 100px;
}

.box-listusers .box-tablelistusers .tablelistusers tbody tr td:nth-child(6) {
  width: 10%;
  min-width: 140px;
  max-width: 140px;
}

.box-listusers .box-tablelistusers .tablelistusers tbody tr td:nth-child(7) {
  width: 10%;
  min-width: 140px;
  max-width: 140px;
}

.box-listusers .box-tablelistusers .tablelistusers tbody tr td:nth-child(8) {
  width: 4%;
  min-width: 45px;
  max-width: 45px;
}

.box-listusers .box-tablelistusers .tablelistusers tbody tr td:nth-child(9) {
  width: 6%;
  min-width: 70px;
  max-width: 70px;
}

.box-listusers .box-tablelistusers .tablelistusers tbody tr td:nth-child(10) {
  width: 4%;
  min-width: 45px;
  max-width: 45px;
}

.box-listusers .box-tablelistusers .tablelistusers tbody tr td .box-keyed .dropdown-menu {
  padding: 0;
  margin: 0;
}

.box-listusers .box-tablelistusers .tablelistusers tbody tr td .box-keyed .dropdown-menu ul li a {
  padding: 4px 16px;
  cursor: pointer;
  color: #333;
  display: block;
  font-size: 14px;
}

.box-listusers .box-tablelistusers .tablefixedhead .box-tbodytable {
  max-height: calc(100vh - 390px);
}

.box-releasestudy {
  padding: 8px 16px 16px 16px;
}

.box-releasestudy .box-groupformrelease .grpform-input .txt-label {
  width: 150px;
  margin-right: 10px;
}

.box-releasestudy .box-groupformrelease .grpform-input .frm-input {
  width: calc(100% - 150px - 10px);
}

.box-clonestudy {
  padding: 16px;
}

.box-clonestudy .box-groupformclone .grpform-input .txt-label {
  width: 100px;
}

.box-clonestudy .box-groupformclone .grpform-input .frm-input {
  width: calc(100% - 100px - 60px);
}

.box-clonestudy .box-groupformclone .grpform-input .frm-input .form-control {
  width: 500px;
}

.box-Property {
  padding: 16px;
}

.box-Property .tablefixedhead .table-property thead {
  height: 30px;
}

.box-Property .tablefixedhead .table-property thead tr th {
  padding: 0;
}

.box-Property .tablefixedhead .table-property thead tr th .txt-display {
  padding: 4px 2px;
}

.box-Property .tablefixedhead .table-property thead tr th.col-id {
  width: 8%;
  min-width: 110px;
  max-width: 110px;
}

.box-Property .tablefixedhead .table-property thead tr th.col-groupcolum {
  width: 25%;
  min-width: 320px;
  max-width: 320px;
}

.box-Property .tablefixedhead .table-property thead tr th.col-groupcolum .group-coloumn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.box-Property .tablefixedhead .table-property thead tr th.col-groupcolum .group-coloumn .colum-type {
  width: 40%;
  text-align: center;
  border-right: 1px solid #ddd;
  padding: 4px 2px;
  min-width: 115px;
}

.box-Property .tablefixedhead .table-property thead tr th.col-groupcolum .group-coloumn .colum-data {
  width: 50%;
  text-align: center;
  padding: 4px 2px;
  border-right: 1px solid #ddd;
  min-width: 145px;
}

.box-Property .tablefixedhead .table-property thead tr th.col-groupcolum .group-coloumn .colum-delete {
  width: 10%;
  text-align: center;
  padding: 4px 2px;
  min-width: 50px;
}

.box-Property .tablefixedhead .table-property tbody:nth-child(even) {
  background: #f9f9f9;
}

.box-Property .tablefixedhead .table-property tbody:nth-child(even) tr td {
  background: #f9f9f9;
}

.box-Property .tablefixedhead .table-property tbody:nth-child(odd) {
  background: #fff;
}

.box-Property .tablefixedhead .table-property tbody:nth-child(odd) tr td {
  background: #fff;
}

.box-Property .tablefixedhead .table-property tbody tr td {
  padding: 0;
}

.box-Property .tablefixedhead .table-property tbody tr td .txt-display {
  padding: 4px 2px;
  text-align: center;
}

.box-Property .tablefixedhead .table-property tbody tr td.col-id {
  width: 8%;
  min-width: 110px;
  max-width: 110px;
}

.box-Property .tablefixedhead .table-property tbody tr td.col-groupcolum {
  width: 25%;
  min-width: 320px;
  max-width: 320px;
  vertical-align: top;
}

.box-Property .tablefixedhead .table-property tbody tr td.col-groupcolum .group-coloumn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.box-Property .tablefixedhead .table-property tbody tr td.col-groupcolum .group-coloumn .colum-type {
  width: 45%;
  text-align: center;
  padding: 4px 2px;
}

.box-Property .tablefixedhead .table-property tbody tr td.col-groupcolum .group-coloumn .colum-data {
  width: 45%;
  text-align: center;
  padding: 4px 2px;
}

.box-Property .tablefixedhead .table-property tbody tr td.col-groupcolum .group-coloumn .colum-delete {
  width: 10%;
  text-align: center;
  padding: 4px 2px;
  min-width: 50px;
}

.box-Property .tablefixedhead .table-property tbody tr td.col-groupcolum .group-coloumn .colum-delete .icon-deletered {
  height: 30px;
}

.box-Property .tablefixedhead .table-property tbody tr td.col-groupcolum .rows-iconclone {
  padding: 5px;
  text-align: center;
}

.box-Property .tablefixedhead .box-tbodytable {
  max-height: calc(100vh - 220px);
}

.box-Property .dropdown .dropdown-menu {
  min-width: 600px;
  width: auto;
  margin-top: 0px;
}

.box-Property .dropdown .dropdown-menu .dropdown-item {
  padding: 5px;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.box-Property .dropdown .dropdown-menu .dropdown-item span {
  width: 30%;
  word-break: break-all;
  white-space: pre-wrap;
}

.box-Property .dropdown .dropdown-menu .dropdown-item span:nth-child(1) {
  width: 23%;
}

.box-Property .dropdown .dropdown-menu .dropdown-item span:nth-child(2) {
  width: 57%;
}

.box-Property .dropdown .dropdown-menu .dropdown-item span:nth-child(3) {
  width: 20%;
}

.box-Property .dropdown .dropdown-menu .dropdown-item:nth-child(even) {
  background: #f8f9fa;
}

.box-Schedule {
  padding: 16px;
}

.box-Schedule .table-box-Schedule thead tr th {
  min-width: 100px;
  max-width: 100px;
  width: 10%;
}

.box-Schedule .table-box-Schedule tbody tr td {
  min-width: 100px;
  max-width: 100px;
  width: 10%;
  background: #fff;
}

.box-Schedule .table-box-Schedule tbody tr:nth-child(even) td {
  background: #f9f9f9;
}

.box-Schedule .table-box-Schedule tbody tr:nth-child(odd) td {
  background: #fff;
}

.box-Schedule .table-box-Schedule.tableleft thead tr th.tbordertrans {
  border-bottom: 1px solid #445466;
}

.box-Schedule .grouptwotablefixedhead {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.box-Schedule .grouptwotablefixedhead .boxtableleft {
  width: 200px;
  border-right: 1px solid #ddd;
}

.box-Schedule .grouptwotablefixedhead .boxtableleft .box-theadtable {
  overflow: hidden;
}

.box-Schedule .grouptwotablefixedhead .boxtableleft .box-tbodytable01 {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 135px);
  position: relative;
  z-index: 1;
}

.box-Schedule .grouptwotablefixedhead .boxtablescroll {
  width: auto;
  min-width: 100px;
  border-right: 1px solid #ddd;
}

.box-Schedule .grouptwotablefixedhead .boxtablescroll .box-theadtable {
  overflow: hidden;
}

.box-Schedule .grouptwotablefixedhead .boxtablescroll .box-tbodytable {
  overflow-x: auto;
  overflow-y: scroll;
  max-height: calc(100vh - 135px);
  position: relative;
  z-index: 2;
}

.box-Schedule .table-box-Schedule .div-thead .div-tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.box-Schedule .table-box-Schedule .div-thead .div-tr .div-th {
  background: #445466;
  color: #fff;
  padding: 4px 2px;
  min-width: 100px;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  text-align: center;
  font-weight: bold;
  word-break: break-all;
  vertical-align: middle;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.box-Schedule .table-box-Schedule .div-thead .div-tr .div-th:last-child {
  border-right: 1px solid #ddd;
}

.box-Schedule .table-box-Schedule .div-thead .div-tr .div-th.tbordertrans {
  border-top: 1px solid transparent;
}

.box-Schedule .table-box-Schedule .div-thead .div-tr .div-th.colspan2 {
  min-width: 200px;
}

.box-Schedule .table-box-Schedule .div-thead .div-tr .div-th.colspan3 {
  min-width: 300px;
}

.box-Schedule .table-box-Schedule .div-thead .div-tr .div-th.colspan4 {
  min-width: 400px;
}

.box-Schedule .table-box-Schedule .div-thead .div-tr .div-th.colspan5 {
  min-width: 500px;
}

.box-Schedule .table-box-Schedule .div-thead .div-tr .div-th.colspan6 {
  min-width: 600px;
}

.box-Schedule .table-box-Schedule .div-thead .div-tr .div-th.colspan7 {
  min-width: 700px;
}

.box-Schedule .table-box-Schedule .div-thead .div-tr .div-th.colspan8 {
  min-width: 800px;
}

.box-Schedule .table-box-Schedule .div-tbody .div-tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.box-Schedule .table-box-Schedule .div-tbody .div-tr .div-td {
  padding: 4px 2px;
  min-width: 100px;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  text-align: center;
  background: #fff;
  word-break: break-all;
  vertical-align: middle;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.box-Schedule .table-box-Schedule .div-tbody .div-tr .div-td:last-child {
  border-right: 1px solid #ddd;
}

.box-Schedule .table-box-Schedule .div-tbody .div-tr:last-child .div-td {
  border-bottom: 1px solid #ddd;
}

.box-Schedule .table-box-Schedule .div-tbody .div-tr:nth-child(even) .div-td {
  background: #f9f9f9;
}

.box-Schedule .table-box-Schedule .div-tbody .div-tr:nth-child(odd) .div-td {
  background: #fff;
}

.box-Schedule .table-box-Schedule.tableleft .div-thead .div-th {
  width: 100%;
  min-width: 200px;
}

.box-Schedule .table-box-Schedule.tableleft .div-tbody .div-td {
  width: 100%;
  min-width: 200px;
}

.box-tableprivilege .table-box-privilege .div-thead .div-tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.box-tableprivilege .table-box-privilege .div-thead .div-tr .div-th {
  background: #445466;
  color: #fff;
  padding: 4px 2px;
  min-width: 100px;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  text-align: center;
  font-weight: bold;
  word-break: break-all;
  vertical-align: middle;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.box-tableprivilege .table-box-privilege .div-thead .div-tr .div-th:last-child {
  border-right: 1px solid #ddd;
}

.box-tableprivilege .table-box-privilege .div-thead .div-tr .div-th.tbordertrans {
  border-top: 1px solid transparent;
}

.box-tableprivilege .table-box-privilege .div-tbody .div-tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.box-tableprivilege .table-box-privilege .div-tbody .div-tr .div-td {
  padding: 4px 2px;
  min-width: 100px;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  text-align: center;
  background: #fff;
  word-break: break-all;
  vertical-align: middle;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.box-tableprivilege .table-box-privilege .div-tbody .div-tr .div-td:last-child {
  border-right: 1px solid #ddd;
}

.box-tableprivilege .table-box-privilege .div-tbody .div-tr:last-child .div-td {
  border-bottom: 1px solid #ddd;
}

.box-tableprivilege .table-box-privilege .div-tbody .div-tr:nth-child(even) .div-td {
  background: #f9f9f9;
}

.box-tableprivilege .table-box-privilege .div-tbody .div-tr:nth-child(odd) .div-td {
  background: #fff;
}

.box-tableprivilege .table-box-privilege.tableleft .div-thead .div-th.col-larged {
  min-width: 350px;
}

.box-tableprivilege .table-box-privilege.tableleft .div-thead .div-th.col-small {
  min-width: 150px;
}

.box-tableprivilege .table-box-privilege.tableleft .div-tbody .div-td.col-larged {
  min-width: 350px;
}

.box-tableprivilege .table-box-privilege.tableleft .div-tbody .div-td.col-small {
  min-width: 150px;
}

.box-tableprivilege .grouptwotablefixedhead {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.box-tableprivilege .grouptwotablefixedhead .boxtableleft {
  width: 500px;
  border-right: 1px solid #ddd;
}

.box-tableprivilege .grouptwotablefixedhead .boxtableleft .box-theadtable {
  overflow: hidden;
}

.box-tableprivilege .grouptwotablefixedhead .boxtableleft .box-tbodytable01 {
  overflow-y: hidden;
  overflow-x: hidden;
  max-height: calc(100vh - 245px);
  position: relative;
  z-index: 1;
}

.box-tableprivilege .grouptwotablefixedhead .boxtablescroll {
  width: auto;
  border-right: 1px solid #ddd;
  min-width: 100px;
}

.box-tableprivilege .grouptwotablefixedhead .boxtablescroll .box-theadtable {
  overflow: hidden;
}

.box-tableprivilege .grouptwotablefixedhead .boxtablescroll .box-tbodytable {
  overflow-x: auto;
  overflow-y: scroll;
  max-height: calc(100vh - 245px);
  position: relative;
  z-index: 2;
}

.icon-browsing {
  background: url("../images/icon-browsing.png") no-repeat center;
  background-size: 24px;
  width: 24px;
  height: 24px;
  display: inline-block;
  cursor: pointer;
}

.icon-download {
  background: url("../images/icon-download.png") no-repeat center;
  background-size: 24px;
  width: 24px;
  height: 24px;
  display: inline-block;
  cursor: pointer;
}

.pd {
  padding: 16px;
}

.pd-tb {
  padding: 16px;
}

#login {
  padding: 0;
}

#box-login {
  background: #b7e0d7;
  background: -moz-linear-gradient(-45deg, #b7e0d7 0%, #89cfc0 11%, #029f7e 100%);
  background: -webkit-linear-gradient(-45deg, #b7e0d7 0%, #89cfc0 11%, #029f7e 100%);
  background: -webkit-linear-gradient(315deg, #b7e0d7 0%, #89cfc0 11%, #029f7e 100%);
  background: -o-linear-gradient(315deg, #b7e0d7 0%, #89cfc0 11%, #029f7e 100%);
  background: linear-gradient(135deg, #b7e0d7 0%, #89cfc0 11%, #029f7e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b7e0d7', endColorstr='#029f7e',GradientType=1 );
}

.login-cmn {
  padding: 40px 0;
  height: calc(100vh - 37px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.login-cmn .logo-ctm a {
  display: block;
}

.login-cmn .box-login {
  background: #fff;
  width: 500px;
  padding: 24px;
}

.login-cmn .btn-login .btn {
  min-width: 150px;
}

.group-btn .btn {
  min-width: 150px;
}

.group-form ul {
  margin: 0 0 0 -24px;
}

.group-form ul li {
  float: left;
  padding: 0 0 0 24px;
  width: 33.333333%;
}

.group-form ul li:nth-child(1) .group-form-fl label, .group-form ul li:nth-child(3) .group-form-fl label {
  width: 50px;
  margin-right: 8px;
}

.group-form ul li:nth-child(1) .group-form-fl .form-control, .group-form ul li:nth-child(3) .group-form-fl .form-control {
  width: calc(100% - 58px);
}

.group-form ul li:nth-child(2) .group-form-fl label {
  width: 100px;
  margin-right: 8px;
}

.group-form ul li:nth-child(2) .group-form-fl .form-control {
  width: calc(100% - 108px);
}

.group-form .group-form-fl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.list-exam-page .grpform-input {
  margin-bottom: 8px;
}

.list-exam-page .grpform-input .frm-input .form-control {
  width: 100px;
}

.list-exam-page .grpform-input .txt-unit {
  line-height: 33px;
  padding-left: 10px;
}

.list-exam-page .table-lstexam tr th,
.list-exam-page .table-lstexam tr td {
  max-width: 150px;
  min-width: 150px;
}

.list-exam-page .table-lstexam tr th:nth-child(1),
  .list-exam-page .table-lstexam tr td:nth-child(1) {
  min-width: 40px;
  max-width: 40px;
}

.list-exam-page .table-lstexam tr th:nth-child(5),
  .list-exam-page .table-lstexam tr td:nth-child(5) {
  min-width: 250px;
  max-width: 250px;
}

.list-exam-page .table-lstexam tr th:nth-child(6),
  .list-exam-page .table-lstexam tr td:nth-child(6) {
  min-width: 100px;
  max-width: 100px;
}

.list-exam-page .box-tbodytable {
  /*
		max-height: 100%;
		height: calc(100vh - 436px);
		*/
  max-height: calc(100vh - 372px);
}

.group-form-create {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.group-form-create .group-required {
  width: 260px;
  padding-top: 6px;
}

.group-form-create .group-required.group-required-mg {
  padding-top: 7px;
}

.group-form-create .group-required.group-required-mg-fl {
  padding-top: 0px;
}

.group-form-create .group-input {
  width: calc(80% - 200px);
  padding-left: 16px;
}

.group-form-create .group-input .group-input-fl .form-control {
  width: 280px;
}

.group-form-create .group-input .group-checkbox {
  padding: 4px 0 0;
}

.group-form-create .group-input .group-checkbox .custom-control {
  margin-right: 16px;
}

.modal-HistoryofAudit .tablefixedhead .tbl-editExam-modal tr:nth-of-type(2n+1), .modal-HistoryofCodeList .tablefixedhead .tbl-editExam-modal tr:nth-of-type(2n+1), .modal-HistoryOfEcs .tablefixedhead .tbl-editExam-modal tr:nth-of-type(2n+1) {
  background-color: #F9F9F9;
}

.modal-HistoryofAudit .tablefixedhead .tbl-editExam-modal tr th, .modal-HistoryofCodeList .tablefixedhead .tbl-editExam-modal tr th, .modal-HistoryOfEcs .tablefixedhead .tbl-editExam-modal tr th,
.modal-HistoryofAudit .tablefixedhead .tbl-editExam-modal tr td,
.modal-HistoryofCodeList .tablefixedhead .tbl-editExam-modal tr td,
.modal-HistoryOfEcs .tablefixedhead .tbl-editExam-modal tr td {
  max-width: 60px;
  min-width: 60px;
  text-align: left;
  font-weight: normal;
}

.modal-HistoryofAudit .tablefixedhead .tbl-editExam-modal tr th:nth-child(2), .modal-HistoryofCodeList .tablefixedhead .tbl-editExam-modal tr th:nth-child(2), .modal-HistoryOfEcs .tablefixedhead .tbl-editExam-modal tr th:nth-child(2), .modal-HistoryofAudit .tablefixedhead .tbl-editExam-modal tr th:nth-child(3), .modal-HistoryofCodeList .tablefixedhead .tbl-editExam-modal tr th:nth-child(3), .modal-HistoryOfEcs .tablefixedhead .tbl-editExam-modal tr th:nth-child(3), .modal-HistoryofAudit .tablefixedhead .tbl-editExam-modal tr th:nth-child(4), .modal-HistoryofCodeList .tablefixedhead .tbl-editExam-modal tr th:nth-child(4), .modal-HistoryOfEcs .tablefixedhead .tbl-editExam-modal tr th:nth-child(4),
  .modal-HistoryofAudit .tablefixedhead .tbl-editExam-modal tr td:nth-child(2),
  .modal-HistoryofCodeList .tablefixedhead .tbl-editExam-modal tr td:nth-child(2),
  .modal-HistoryOfEcs .tablefixedhead .tbl-editExam-modal tr td:nth-child(2),
  .modal-HistoryofAudit .tablefixedhead .tbl-editExam-modal tr td:nth-child(3),
  .modal-HistoryofCodeList .tablefixedhead .tbl-editExam-modal tr td:nth-child(3),
  .modal-HistoryOfEcs .tablefixedhead .tbl-editExam-modal tr td:nth-child(3),
  .modal-HistoryofAudit .tablefixedhead .tbl-editExam-modal tr td:nth-child(4),
  .modal-HistoryofCodeList .tablefixedhead .tbl-editExam-modal tr td:nth-child(4),
  .modal-HistoryOfEcs .tablefixedhead .tbl-editExam-modal tr td:nth-child(4) {
  max-width: 140px;
  min-width: 140px;
}

.modal-HistoryofAudit .tablefixedhead .tbl-editExam-modal tr th:last-child, .modal-HistoryofCodeList .tablefixedhead .tbl-editExam-modal tr th:last-child, .modal-HistoryOfEcs .tablefixedhead .tbl-editExam-modal tr th:last-child,
  .modal-HistoryofAudit .tablefixedhead .tbl-editExam-modal tr td:last-child,
  .modal-HistoryofCodeList .tablefixedhead .tbl-editExam-modal tr td:last-child,
  .modal-HistoryOfEcs .tablefixedhead .tbl-editExam-modal tr td:last-child {
  max-width: 240px;
  min-width: 240px;
}

.modal-HistoryofAudit .tablefixedhead .tbl-editExam-modal tr th:nth-child(5), .modal-HistoryofCodeList .tablefixedhead .tbl-editExam-modal tr th:nth-child(5), .modal-HistoryOfEcs .tablefixedhead .tbl-editExam-modal tr th:nth-child(5), .modal-HistoryofAudit .tablefixedhead .tbl-editExam-modal tr th:nth-child(6), .modal-HistoryofCodeList .tablefixedhead .tbl-editExam-modal tr th:nth-child(6), .modal-HistoryOfEcs .tablefixedhead .tbl-editExam-modal tr th:nth-child(6), .modal-HistoryofAudit .tablefixedhead .tbl-editExam-modal tr th:nth-child(7), .modal-HistoryofCodeList .tablefixedhead .tbl-editExam-modal tr th:nth-child(7), .modal-HistoryOfEcs .tablefixedhead .tbl-editExam-modal tr th:nth-child(7),
  .modal-HistoryofAudit .tablefixedhead .tbl-editExam-modal tr td:nth-child(5),
  .modal-HistoryofCodeList .tablefixedhead .tbl-editExam-modal tr td:nth-child(5),
  .modal-HistoryOfEcs .tablefixedhead .tbl-editExam-modal tr td:nth-child(5),
  .modal-HistoryofAudit .tablefixedhead .tbl-editExam-modal tr td:nth-child(6),
  .modal-HistoryofCodeList .tablefixedhead .tbl-editExam-modal tr td:nth-child(6),
  .modal-HistoryOfEcs .tablefixedhead .tbl-editExam-modal tr td:nth-child(6),
  .modal-HistoryofAudit .tablefixedhead .tbl-editExam-modal tr td:nth-child(7),
  .modal-HistoryofCodeList .tablefixedhead .tbl-editExam-modal tr td:nth-child(7),
  .modal-HistoryOfEcs .tablefixedhead .tbl-editExam-modal tr td:nth-child(7) {
  text-align: center;
}

.modal-HistoryofAudit .tablefixedhead .tbl-editExam-modal tr th:first-of-type, .modal-HistoryofCodeList .tablefixedhead .tbl-editExam-modal tr th:first-of-type, .modal-HistoryOfEcs .tablefixedhead .tbl-editExam-modal tr th:first-of-type {
  text-align: center;
}

.modal-HistoryofAudit .tablefixedhead .tbl-editExam-modal tr td:first-of-type, .modal-HistoryofCodeList .tablefixedhead .tbl-editExam-modal tr td:first-of-type, .modal-HistoryOfEcs .tablefixedhead .tbl-editExam-modal tr td:first-of-type {
  text-align: right;
}

.modal-HistoryofAudit .tablefixedhead .table-search-fl tr th, .modal-HistoryofCodeList .tablefixedhead .table-search-fl tr th, .modal-HistoryOfEcs .tablefixedhead .table-search-fl tr th,
.modal-HistoryofAudit .tablefixedhead .table-search-fl tr td,
.modal-HistoryofCodeList .tablefixedhead .table-search-fl tr td,
.modal-HistoryOfEcs .tablefixedhead .table-search-fl tr td {
  max-width: 50px;
  min-width: 50px;
  font-weight: normal;
}

.modal-HistoryofAudit .tablefixedhead .table-search-fl tr th:nth-child(5), .modal-HistoryofCodeList .tablefixedhead .table-search-fl tr th:nth-child(5), .modal-HistoryOfEcs .tablefixedhead .table-search-fl tr th:nth-child(5), .modal-HistoryofAudit .tablefixedhead .table-search-fl tr th:nth-child(6), .modal-HistoryofCodeList .tablefixedhead .table-search-fl tr th:nth-child(6), .modal-HistoryOfEcs .tablefixedhead .table-search-fl tr th:nth-child(6), .modal-HistoryofAudit .tablefixedhead .table-search-fl tr th:nth-child(7), .modal-HistoryofCodeList .tablefixedhead .table-search-fl tr th:nth-child(7), .modal-HistoryOfEcs .tablefixedhead .table-search-fl tr th:nth-child(7),
  .modal-HistoryofAudit .tablefixedhead .table-search-fl tr td:nth-child(5),
  .modal-HistoryofCodeList .tablefixedhead .table-search-fl tr td:nth-child(5),
  .modal-HistoryOfEcs .tablefixedhead .table-search-fl tr td:nth-child(5),
  .modal-HistoryofAudit .tablefixedhead .table-search-fl tr td:nth-child(6),
  .modal-HistoryofCodeList .tablefixedhead .table-search-fl tr td:nth-child(6),
  .modal-HistoryOfEcs .tablefixedhead .table-search-fl tr td:nth-child(6),
  .modal-HistoryofAudit .tablefixedhead .table-search-fl tr td:nth-child(7),
  .modal-HistoryofCodeList .tablefixedhead .table-search-fl tr td:nth-child(7),
  .modal-HistoryOfEcs .tablefixedhead .table-search-fl tr td:nth-child(7) {
  text-align: left;
}

.modal-HistoryofAudit .tablefixedhead .table-search-fl tr th:nth-child(5), .modal-HistoryofCodeList .tablefixedhead .table-search-fl tr th:nth-child(5), .modal-HistoryOfEcs .tablefixedhead .table-search-fl tr th:nth-child(5),
  .modal-HistoryofAudit .tablefixedhead .table-search-fl tr td:nth-child(5),
  .modal-HistoryofCodeList .tablefixedhead .table-search-fl tr td:nth-child(5),
  .modal-HistoryOfEcs .tablefixedhead .table-search-fl tr td:nth-child(5) {
  max-width: 250px;
  min-width: 250px;
}

.modal-HistoryofAudit .tablefixedhead .table-search-fl tr th:nth-child(7), .modal-HistoryofCodeList .tablefixedhead .table-search-fl tr th:nth-child(7), .modal-HistoryOfEcs .tablefixedhead .table-search-fl tr th:nth-child(7),
  .modal-HistoryofAudit .tablefixedhead .table-search-fl tr td:nth-child(7),
  .modal-HistoryofCodeList .tablefixedhead .table-search-fl tr td:nth-child(7),
  .modal-HistoryOfEcs .tablefixedhead .table-search-fl tr td:nth-child(7) {
  max-width: 200px;
  min-width: 200px;
}

.modal-HistoryofAudit .tablefixedhead .table-search-fl tr th:nth-child(3), .modal-HistoryofCodeList .tablefixedhead .table-search-fl tr th:nth-child(3), .modal-HistoryOfEcs .tablefixedhead .table-search-fl tr th:nth-child(3), .modal-HistoryofAudit .tablefixedhead .table-search-fl tr th:nth-child(4), .modal-HistoryofCodeList .tablefixedhead .table-search-fl tr th:nth-child(4), .modal-HistoryOfEcs .tablefixedhead .table-search-fl tr th:nth-child(4), .modal-HistoryofAudit .tablefixedhead .table-search-fl tr th:nth-child(6), .modal-HistoryofCodeList .tablefixedhead .table-search-fl tr th:nth-child(6), .modal-HistoryOfEcs .tablefixedhead .table-search-fl tr th:nth-child(6),
  .modal-HistoryofAudit .tablefixedhead .table-search-fl tr td:nth-child(3),
  .modal-HistoryofCodeList .tablefixedhead .table-search-fl tr td:nth-child(3),
  .modal-HistoryOfEcs .tablefixedhead .table-search-fl tr td:nth-child(3),
  .modal-HistoryofAudit .tablefixedhead .table-search-fl tr td:nth-child(4),
  .modal-HistoryofCodeList .tablefixedhead .table-search-fl tr td:nth-child(4),
  .modal-HistoryOfEcs .tablefixedhead .table-search-fl tr td:nth-child(4),
  .modal-HistoryofAudit .tablefixedhead .table-search-fl tr td:nth-child(6),
  .modal-HistoryofCodeList .tablefixedhead .table-search-fl tr td:nth-child(6),
  .modal-HistoryOfEcs .tablefixedhead .table-search-fl tr td:nth-child(6) {
  max-width: 100px;
  min-width: 100px;
}

.modal-HistoryofAudit .tablefixedhead .table-search-fl tr td:nth-child(1), .modal-HistoryofCodeList .tablefixedhead .table-search-fl tr td:nth-child(1), .modal-HistoryOfEcs .tablefixedhead .table-search-fl tr td:nth-child(1) {
  text-align: right;
}

.modal-HistoryofAudit .tablefixedhead .table-search-fl tr td:nth-child(2), .modal-HistoryofCodeList .tablefixedhead .table-search-fl tr td:nth-child(2), .modal-HistoryOfEcs .tablefixedhead .table-search-fl tr td:nth-child(2),
.modal-HistoryofAudit .tablefixedhead .table-search-fl tr td:nth-child(3),
.modal-HistoryofCodeList .tablefixedhead .table-search-fl tr td:nth-child(3),
.modal-HistoryOfEcs .tablefixedhead .table-search-fl tr td:nth-child(3),
.modal-HistoryofAudit .tablefixedhead .table-search-fl tr td:nth-child(4),
.modal-HistoryofCodeList .tablefixedhead .table-search-fl tr td:nth-child(4),
.modal-HistoryOfEcs .tablefixedhead .table-search-fl tr td:nth-child(4) {
  text-align: center;
}

.modal-HistoryofAudit .tablefixedhead .table-history-cycle tr th, .modal-HistoryofCodeList .tablefixedhead .table-history-cycle tr th, .modal-HistoryOfEcs .tablefixedhead .table-history-cycle tr th,
.modal-HistoryofAudit .tablefixedhead .table-history-cycle tr td,
.modal-HistoryofCodeList .tablefixedhead .table-history-cycle tr td,
.modal-HistoryOfEcs .tablefixedhead .table-history-cycle tr td {
  max-width: 80px;
  min-width: 80px;
  font-weight: normal;
}

.modal-HistoryofAudit .tablefixedhead .table-history-cycle tr th:nth-child(1), .modal-HistoryofCodeList .tablefixedhead .table-history-cycle tr th:nth-child(1), .modal-HistoryOfEcs .tablefixedhead .table-history-cycle tr th:nth-child(1),
  .modal-HistoryofAudit .tablefixedhead .table-history-cycle tr td:nth-child(1),
  .modal-HistoryofCodeList .tablefixedhead .table-history-cycle tr td:nth-child(1),
  .modal-HistoryOfEcs .tablefixedhead .table-history-cycle tr td:nth-child(1) {
  max-width: 50px;
  min-width: 50px;
}

.modal-HistoryofAudit .tablefixedhead .table-history-cycle tr th:nth-child(2), .modal-HistoryofCodeList .tablefixedhead .table-history-cycle tr th:nth-child(2), .modal-HistoryOfEcs .tablefixedhead .table-history-cycle tr th:nth-child(2), .modal-HistoryofAudit .tablefixedhead .table-history-cycle tr th:nth-child(3), .modal-HistoryofCodeList .tablefixedhead .table-history-cycle tr th:nth-child(3), .modal-HistoryOfEcs .tablefixedhead .table-history-cycle tr th:nth-child(3), .modal-HistoryofAudit .tablefixedhead .table-history-cycle tr th:nth-child(8), .modal-HistoryofCodeList .tablefixedhead .table-history-cycle tr th:nth-child(8), .modal-HistoryOfEcs .tablefixedhead .table-history-cycle tr th:nth-child(8),
  .modal-HistoryofAudit .tablefixedhead .table-history-cycle tr td:nth-child(2),
  .modal-HistoryofCodeList .tablefixedhead .table-history-cycle tr td:nth-child(2),
  .modal-HistoryOfEcs .tablefixedhead .table-history-cycle tr td:nth-child(2),
  .modal-HistoryofAudit .tablefixedhead .table-history-cycle tr td:nth-child(3),
  .modal-HistoryofCodeList .tablefixedhead .table-history-cycle tr td:nth-child(3),
  .modal-HistoryOfEcs .tablefixedhead .table-history-cycle tr td:nth-child(3),
  .modal-HistoryofAudit .tablefixedhead .table-history-cycle tr td:nth-child(8),
  .modal-HistoryofCodeList .tablefixedhead .table-history-cycle tr td:nth-child(8),
  .modal-HistoryOfEcs .tablefixedhead .table-history-cycle tr td:nth-child(8) {
  max-width: 100px;
  min-width: 100px;
}

.modal-HistoryofAudit .tablefixedhead .table-history-cycle tr th:nth-child(6), .modal-HistoryofCodeList .tablefixedhead .table-history-cycle tr th:nth-child(6), .modal-HistoryOfEcs .tablefixedhead .table-history-cycle tr th:nth-child(6),
  .modal-HistoryofAudit .tablefixedhead .table-history-cycle tr td:nth-child(6),
  .modal-HistoryofCodeList .tablefixedhead .table-history-cycle tr td:nth-child(6),
  .modal-HistoryOfEcs .tablefixedhead .table-history-cycle tr td:nth-child(6) {
  max-width: 150px;
  min-width: 150px;
}

.modal-HistoryofAudit .tablefixedhead .table-history-cycle tr th:nth-child(9), .modal-HistoryofCodeList .tablefixedhead .table-history-cycle tr th:nth-child(9), .modal-HistoryOfEcs .tablefixedhead .table-history-cycle tr th:nth-child(9),
  .modal-HistoryofAudit .tablefixedhead .table-history-cycle tr td:nth-child(9),
  .modal-HistoryofCodeList .tablefixedhead .table-history-cycle tr td:nth-child(9),
  .modal-HistoryOfEcs .tablefixedhead .table-history-cycle tr td:nth-child(9) {
  max-width: 250px;
  min-width: 250px;
}

.modal-HistoryofAudit .tablefixedhead .table-history-cycle tr th, .modal-HistoryofCodeList .tablefixedhead .table-history-cycle tr th, .modal-HistoryOfEcs .tablefixedhead .table-history-cycle tr th {
  text-align: center;
}

.modal-HistoryofAudit .tablefixedhead .table-history-cycle tr th:nth-child(8), .modal-HistoryofCodeList .tablefixedhead .table-history-cycle tr th:nth-child(8), .modal-HistoryOfEcs .tablefixedhead .table-history-cycle tr th:nth-child(8), .modal-HistoryofAudit .tablefixedhead .table-history-cycle tr th:nth-child(9), .modal-HistoryofCodeList .tablefixedhead .table-history-cycle tr th:nth-child(9), .modal-HistoryOfEcs .tablefixedhead .table-history-cycle tr th:nth-child(9) {
  text-align: left;
}

.modal-HistoryofAudit .tablefixedhead .table-history-cycle tr td:nth-child(1),
.modal-HistoryofCodeList .tablefixedhead .table-history-cycle tr td:nth-child(1),
.modal-HistoryOfEcs .tablefixedhead .table-history-cycle tr td:nth-child(1) {
  text-align: right;
}

.modal-HistoryofAudit .tablefixedhead .table-history-cycle tr td:nth-child(2),
.modal-HistoryofCodeList .tablefixedhead .table-history-cycle tr td:nth-child(2),
.modal-HistoryOfEcs .tablefixedhead .table-history-cycle tr td:nth-child(2),
.modal-HistoryofAudit .tablefixedhead .table-history-cycle tr td:nth-child(3),
.modal-HistoryofCodeList .tablefixedhead .table-history-cycle tr td:nth-child(3),
.modal-HistoryOfEcs .tablefixedhead .table-history-cycle tr td:nth-child(3), .modal-HistoryofAudit .tablefixedhead .table-history-cycle tr td:nth-child(4), .modal-HistoryofCodeList .tablefixedhead .table-history-cycle tr td:nth-child(4), .modal-HistoryOfEcs .tablefixedhead .table-history-cycle tr td:nth-child(4), .modal-HistoryofAudit .tablefixedhead .table-history-cycle tr td:nth-child(5), .modal-HistoryofCodeList .tablefixedhead .table-history-cycle tr td:nth-child(5), .modal-HistoryOfEcs .tablefixedhead .table-history-cycle tr td:nth-child(5), .modal-HistoryofAudit .tablefixedhead .table-history-cycle tr td:nth-child(7), .modal-HistoryofCodeList .tablefixedhead .table-history-cycle tr td:nth-child(7), .modal-HistoryOfEcs .tablefixedhead .table-history-cycle tr td:nth-child(7) {
  text-align: center;
}

.modal-HistoryofAudit .tablefixedhead .table-history-visit tr th, .modal-HistoryofCodeList .tablefixedhead .table-history-visit tr th, .modal-HistoryOfEcs .tablefixedhead .table-history-visit tr th,
.modal-HistoryofAudit .tablefixedhead .table-history-visit tr td,
.modal-HistoryofCodeList .tablefixedhead .table-history-visit tr td,
.modal-HistoryOfEcs .tablefixedhead .table-history-visit tr td {
  max-width: 80px;
  min-width: 80px;
  font-weight: normal;
}

.modal-HistoryofAudit .tablefixedhead .table-history-visit tr th:nth-child(1), .modal-HistoryofCodeList .tablefixedhead .table-history-visit tr th:nth-child(1), .modal-HistoryOfEcs .tablefixedhead .table-history-visit tr th:nth-child(1),
  .modal-HistoryofAudit .tablefixedhead .table-history-visit tr td:nth-child(1),
  .modal-HistoryofCodeList .tablefixedhead .table-history-visit tr td:nth-child(1),
  .modal-HistoryOfEcs .tablefixedhead .table-history-visit tr td:nth-child(1) {
  max-width: 50px;
  min-width: 50px;
}

.modal-HistoryofAudit .tablefixedhead .table-history-visit tr th:nth-child(2), .modal-HistoryofCodeList .tablefixedhead .table-history-visit tr th:nth-child(2), .modal-HistoryOfEcs .tablefixedhead .table-history-visit tr th:nth-child(2), .modal-HistoryofAudit .tablefixedhead .table-history-visit tr th:nth-child(3), .modal-HistoryofCodeList .tablefixedhead .table-history-visit tr th:nth-child(3), .modal-HistoryOfEcs .tablefixedhead .table-history-visit tr th:nth-child(3), .modal-HistoryofAudit .tablefixedhead .table-history-visit tr th:nth-child(4), .modal-HistoryofCodeList .tablefixedhead .table-history-visit tr th:nth-child(4), .modal-HistoryOfEcs .tablefixedhead .table-history-visit tr th:nth-child(4), .modal-HistoryofAudit .tablefixedhead .table-history-visit tr th:nth-child(7), .modal-HistoryofCodeList .tablefixedhead .table-history-visit tr th:nth-child(7), .modal-HistoryOfEcs .tablefixedhead .table-history-visit tr th:nth-child(7),
  .modal-HistoryofAudit .tablefixedhead .table-history-visit tr td:nth-child(2),
  .modal-HistoryofCodeList .tablefixedhead .table-history-visit tr td:nth-child(2),
  .modal-HistoryOfEcs .tablefixedhead .table-history-visit tr td:nth-child(2),
  .modal-HistoryofAudit .tablefixedhead .table-history-visit tr td:nth-child(3),
  .modal-HistoryofCodeList .tablefixedhead .table-history-visit tr td:nth-child(3),
  .modal-HistoryOfEcs .tablefixedhead .table-history-visit tr td:nth-child(3),
  .modal-HistoryofAudit .tablefixedhead .table-history-visit tr td:nth-child(4),
  .modal-HistoryofCodeList .tablefixedhead .table-history-visit tr td:nth-child(4),
  .modal-HistoryOfEcs .tablefixedhead .table-history-visit tr td:nth-child(4),
  .modal-HistoryofAudit .tablefixedhead .table-history-visit tr td:nth-child(7),
  .modal-HistoryofCodeList .tablefixedhead .table-history-visit tr td:nth-child(7),
  .modal-HistoryOfEcs .tablefixedhead .table-history-visit tr td:nth-child(7) {
  max-width: 100px;
  min-width: 100px;
}

.modal-HistoryofAudit .tablefixedhead .table-history-visit tr th:nth-child(5), .modal-HistoryofCodeList .tablefixedhead .table-history-visit tr th:nth-child(5), .modal-HistoryOfEcs .tablefixedhead .table-history-visit tr th:nth-child(5), .modal-HistoryofAudit .tablefixedhead .table-history-visit tr th:nth-child(8), .modal-HistoryofCodeList .tablefixedhead .table-history-visit tr th:nth-child(8), .modal-HistoryOfEcs .tablefixedhead .table-history-visit tr th:nth-child(8),
  .modal-HistoryofAudit .tablefixedhead .table-history-visit tr td:nth-child(5),
  .modal-HistoryofCodeList .tablefixedhead .table-history-visit tr td:nth-child(5),
  .modal-HistoryOfEcs .tablefixedhead .table-history-visit tr td:nth-child(5),
  .modal-HistoryofAudit .tablefixedhead .table-history-visit tr td:nth-child(8),
  .modal-HistoryofCodeList .tablefixedhead .table-history-visit tr td:nth-child(8),
  .modal-HistoryOfEcs .tablefixedhead .table-history-visit tr td:nth-child(8) {
  max-width: 250px;
  min-width: 250px;
}

.modal-HistoryofAudit .tablefixedhead .table-history-visit tr th, .modal-HistoryofCodeList .tablefixedhead .table-history-visit tr th, .modal-HistoryOfEcs .tablefixedhead .table-history-visit tr th {
  text-align: center;
}

.modal-HistoryofAudit .tablefixedhead .table-history-visit tr th:nth-child(5), .modal-HistoryofCodeList .tablefixedhead .table-history-visit tr th:nth-child(5), .modal-HistoryOfEcs .tablefixedhead .table-history-visit tr th:nth-child(5), .modal-HistoryofAudit .tablefixedhead .table-history-visit tr th:nth-child(7), .modal-HistoryofCodeList .tablefixedhead .table-history-visit tr th:nth-child(7), .modal-HistoryOfEcs .tablefixedhead .table-history-visit tr th:nth-child(7), .modal-HistoryofAudit .tablefixedhead .table-history-visit tr th:nth-child(8), .modal-HistoryofCodeList .tablefixedhead .table-history-visit tr th:nth-child(8), .modal-HistoryOfEcs .tablefixedhead .table-history-visit tr th:nth-child(8) {
  text-align: left;
}

.modal-HistoryofAudit .tablefixedhead .table-history-visit tr td:nth-child(1),
.modal-HistoryofCodeList .tablefixedhead .table-history-visit tr td:nth-child(1),
.modal-HistoryOfEcs .tablefixedhead .table-history-visit tr td:nth-child(1) {
  text-align: right;
}

.modal-HistoryofAudit .tablefixedhead .table-history-visit tr td:nth-child(2),
.modal-HistoryofCodeList .tablefixedhead .table-history-visit tr td:nth-child(2),
.modal-HistoryOfEcs .tablefixedhead .table-history-visit tr td:nth-child(2),
.modal-HistoryofAudit .tablefixedhead .table-history-visit tr td:nth-child(3),
.modal-HistoryofCodeList .tablefixedhead .table-history-visit tr td:nth-child(3),
.modal-HistoryOfEcs .tablefixedhead .table-history-visit tr td:nth-child(3),
.modal-HistoryofAudit .tablefixedhead .table-history-visit tr td:nth-child(4),
.modal-HistoryofCodeList .tablefixedhead .table-history-visit tr td:nth-child(4),
.modal-HistoryOfEcs .tablefixedhead .table-history-visit tr td:nth-child(4), .modal-HistoryofAudit .tablefixedhead .table-history-visit tr td:nth-child(6), .modal-HistoryofCodeList .tablefixedhead .table-history-visit tr td:nth-child(6), .modal-HistoryOfEcs .tablefixedhead .table-history-visit tr td:nth-child(6) {
  text-align: center;
}

.edc-setting-page .group-edc-setting .form-setting:nth-of-type(2n+2) {
  background-color: #F9F9F9;
}

.edc-setting-page .form-setting {
  padding: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.edc-setting-page .form-setting .form-lable {
  width: 200px;
}

.edc-setting-page .form-setting .form-content {
  width: calc(100% - 226px);
  padding: 0 16px;
}

.edc-setting-page .form-setting .form-content.form-content-fl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.edc-setting-page .form-setting .form-item {
  width: 26px;
}

.edc-setting-page .form-setting .form-radio-control .custom-control {
  margin-right: 16px;
}

.edc-setting-page .form-setting .form-radio-control .form-control {
  width: 100px;
  display: inline-block;
}

.edc-setting-page .form-setting .form-radio-control.form-radio-control-fl .custom-control {
  margin-top: 4px;
}

.edc-setting-page .form-setting .form-row {
  width: 300px;
}

.edc-setting-page .form-setting .form-row .form-control {
  width: calc(100% - 68px);
  float: left;
}

.edc-setting-page .form-setting .form-row span {
  float: right;
}

.edc-setting-page .form-setting .form-right {
  width: calc(100% - 300px);
  padding-left: 16px;
}

.edc-setting-page .form-setting .form-sequence {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.edc-setting-page .form-setting .form-sequence .form-control {
  width: 100px;
  margin-left: 16px;
}

.modal-group .grpform-input {
  margin-bottom: 8px;
}

.modal-group .grpform-input .frm-input .form-control {
  width: 100px;
}

.modal-group .grpform-input .txt-unit {
  line-height: 33px;
  padding-left: 10px;
}

.modal-group .tablefixedhead .tbl-setting tr th,
.modal-group .tablefixedhead .tbl-setting tr td {
  max-width: 150px;
  min-width: 150px;
  text-align: left;
  font-weight: normal;
}

.modal-group .tablefixedhead .tbl-setting tr th:nth-child(1),
  .modal-group .tablefixedhead .tbl-setting tr td:nth-child(1) {
  max-width: 60px;
  min-width: 60px;
}

.modal-group .tablefixedhead .tbl-setting tr th:nth-child(4),
  .modal-group .tablefixedhead .tbl-setting tr td:nth-child(4) {
  max-width: 240px;
  min-width: 240px;
}

.modal-group .tablefixedhead .tbl-setting tr th:nth-child(1), .modal-group .tablefixedhead .tbl-setting tr th:nth-child(2) {
  text-align: center;
}

.modal-group .tablefixedhead .tbl-setting tr td:nth-child(1) {
  text-align: right;
}

.modal-group .tablefixedhead .tbl-setting tr td:nth-child(2) {
  text-align: center;
}

.e-Training-page .form-training,
.e-Training-page .form-input,
.e-Training-page .form-input-fl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.e-Training-page .form-training .form-col,
  .e-Training-page .form-input .form-col,
  .e-Training-page .form-input-fl .form-col {
  max-width: 300px;
  min-width: 220px;
  margin-right: 16px;
}

.e-Training-page .form-training .form-input label,
  .e-Training-page .form-input .form-input label,
  .e-Training-page .form-input-fl .form-input label {
  width: 50px;
}

.e-Training-page .form-training .form-input .form-control,
  .e-Training-page .form-input .form-input .form-control,
  .e-Training-page .form-input-fl .form-input .form-control {
  width: calc(100% - 50px);
}

.e-Training-page .form-training .form-btn .btn,
  .e-Training-page .form-input .form-btn .btn,
  .e-Training-page .form-input-fl .form-btn .btn {
  min-width: 100px;
}

.e-Training-page .box-tablecodelist .grpform-input {
  margin-bottom: 8px;
}

.e-Training-page .box-tablecodelist .grpform-input .frm-input .form-control {
  width: 100px;
}

.e-Training-page .box-tablecodelist .grpform-input .txt-unit {
  line-height: 33px;
  padding-left: 10px;
}

.e-Training-page .tablefixedhead .box-tbodytable {
  max-height: calc(100vh - 326px);
}

.e-Training-page .tablefixedhead .table-training tr th,
.e-Training-page .tablefixedhead .table-training tr td {
  text-align: center;
  min-width: 50px;
  max-width: 50px;
}

.e-Training-page .tablefixedhead .table-training tr th:nth-child(2),
  .e-Training-page .tablefixedhead .table-training tr td:nth-child(2) {
  min-width: 150px;
  max-width: 150px;
}

.e-Training-page .tablefixedhead .table-training tr th:nth-child(3),
  .e-Training-page .tablefixedhead .table-training tr td:nth-child(3) {
  min-width: 250px;
  max-width: 250px;
}

.e-Training-page .tablefixedhead .table-training tr th:nth-child(4),
  .e-Training-page .tablefixedhead .table-training tr td:nth-child(4) {
  min-width: 100px;
  max-width: 100px;
}

.e-Training-page .tablefixedhead .table-training tr th:nth-child(6),
  .e-Training-page .tablefixedhead .table-training tr td:nth-child(6) {
  min-width: 80px;
  max-width: 80px;
}

.e-Training-page .tablefixedhead .table-training tr td:nth-child(1) {
  text-align: right;
}

.e-Training-page .tablefixedhead .table-training .text-line-through {
  text-decoration: line-through;
  color: #f00;
}

.modal-itemcreatcode .modal-dialog-w500, .modal-itemeditcode .modal-dialog-w500 {
  max-width: 500px;
}

.modal-itemcreatcode .btn-delete .btn, .modal-itemeditcode .btn-delete .btn {
  min-width: 125px;
}

.modal-itemcreatcode .form-upload, .modal-itemeditcode .form-upload,
.modal-itemcreatcode .form-upload-input,
.modal-itemeditcode .form-upload-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.modal-itemcreatcode .form-upload .form-col-left, .modal-itemeditcode .form-upload .form-col-left,
  .modal-itemcreatcode .form-upload-input .form-col-left,
  .modal-itemeditcode .form-upload-input .form-col-left {
  width: 150px;
}

.modal-itemcreatcode .form-upload .form-col-right, .modal-itemeditcode .form-upload .form-col-right,
  .modal-itemcreatcode .form-upload-input .form-col-right,
  .modal-itemeditcode .form-upload-input .form-col-right {
  width: calc(100% - 150px);
  padding-left: 16px;
}

.modal-itemcreatcode .form-upload .form-txt, .modal-itemeditcode .form-upload .form-txt,
  .modal-itemcreatcode .form-upload-input .form-txt,
  .modal-itemeditcode .form-upload-input .form-txt {
  padding-top: 6px;
}

.modal-itemcreatcode .form-upload-input .form-control, .modal-itemeditcode .form-upload-input .form-control {
  width: calc(100% - 133px);
}

.modal-itemcreatcode .form-upload-input .btn, .modal-itemeditcode .form-upload-input .btn {
  min-width: 125px;
  margin-left: 8px;
}

.modal-itemcreatcode .tablefixedhead .tbl-search th, .modal-itemeditcode .tablefixedhead .tbl-search th,
.modal-itemcreatcode .tablefixedhead .tbl-search td,
.modal-itemeditcode .tablefixedhead .tbl-search td {
  max-width: 60px;
  min-width: 60px;
  font-weight: normal;
  text-align: left;
}

.modal-itemcreatcode .tablefixedhead .tbl-search th:nth-child(2), .modal-itemeditcode .tablefixedhead .tbl-search th:nth-child(2),
  .modal-itemcreatcode .tablefixedhead .tbl-search td:nth-child(2),
  .modal-itemeditcode .tablefixedhead .tbl-search td:nth-child(2) {
  max-width: 250px;
  min-width: 250px;
}

.modal-itemcreatcode .tablefixedhead .tbl-search th:nth-child(5), .modal-itemeditcode .tablefixedhead .tbl-search th:nth-child(5),
  .modal-itemcreatcode .tablefixedhead .tbl-search td:nth-child(5),
  .modal-itemeditcode .tablefixedhead .tbl-search td:nth-child(5) {
  max-width: 200px;
  min-width: 200px;
}

.modal-itemcreatcode .tablefixedhead .tbl-search th:nth-child(1), .modal-itemeditcode .tablefixedhead .tbl-search th:nth-child(1), .modal-itemcreatcode .tablefixedhead .tbl-search th:nth-child(2), .modal-itemeditcode .tablefixedhead .tbl-search th:nth-child(2), .modal-itemcreatcode .tablefixedhead .tbl-search th:nth-child(3), .modal-itemeditcode .tablefixedhead .tbl-search th:nth-child(3) {
  text-align: center;
}

.modal-itemcreatcode .tablefixedhead .tbl-search td:nth-child(1), .modal-itemeditcode .tablefixedhead .tbl-search td:nth-child(1) {
  text-align: right;
}

.modal-itemcreatcode .tablefixedhead .tbl-search td:nth-child(3), .modal-itemeditcode .tablefixedhead .tbl-search td:nth-child(3) {
  text-align: center;
}

.modal-itemcreatcode .tablefixedhead .tbl-search-fl th, .modal-itemeditcode .tablefixedhead .tbl-search-fl th,
.modal-itemcreatcode .tablefixedhead .tbl-search-fl td,
.modal-itemeditcode .tablefixedhead .tbl-search-fl td {
  max-width: 60px;
  min-width: 60px;
  font-weight: normal;
  text-align: left;
}

.modal-itemcreatcode .tablefixedhead .tbl-search-fl th:nth-child(3), .modal-itemeditcode .tablefixedhead .tbl-search-fl th:nth-child(3),
  .modal-itemcreatcode .tablefixedhead .tbl-search-fl td:nth-child(3),
  .modal-itemeditcode .tablefixedhead .tbl-search-fl td:nth-child(3) {
  max-width: 100px;
  min-width: 100px;
}

.modal-itemcreatcode .tablefixedhead .tbl-search-fl th:nth-child(4), .modal-itemeditcode .tablefixedhead .tbl-search-fl th:nth-child(4),
  .modal-itemcreatcode .tablefixedhead .tbl-search-fl td:nth-child(4),
  .modal-itemeditcode .tablefixedhead .tbl-search-fl td:nth-child(4) {
  max-width: 150px;
  min-width: 150px;
}

.modal-itemcreatcode .tablefixedhead .tbl-search-fl th:nth-child(6), .modal-itemeditcode .tablefixedhead .tbl-search-fl th:nth-child(6),
  .modal-itemcreatcode .tablefixedhead .tbl-search-fl td:nth-child(6),
  .modal-itemeditcode .tablefixedhead .tbl-search-fl td:nth-child(6) {
  max-width: 200px;
  min-width: 200px;
}

.modal-itemcreatcode .tablefixedhead .tbl-search-fl th:nth-child(1), .modal-itemeditcode .tablefixedhead .tbl-search-fl th:nth-child(1), .modal-itemcreatcode .tablefixedhead .tbl-search-fl th:nth-child(2), .modal-itemeditcode .tablefixedhead .tbl-search-fl th:nth-child(2), .modal-itemcreatcode .tablefixedhead .tbl-search-fl th:nth-child(3), .modal-itemeditcode .tablefixedhead .tbl-search-fl th:nth-child(3) {
  text-align: center;
}

.modal-itemcreatcode .tablefixedhead .tbl-search-fl td:nth-child(1), .modal-itemeditcode .tablefixedhead .tbl-search-fl td:nth-child(1) {
  text-align: right;
}

.modal-itemcreatcode .tablefixedhead .tbl-search-fl td:nth-child(2), .modal-itemeditcode .tablefixedhead .tbl-search-fl td:nth-child(2),
.modal-itemcreatcode .tablefixedhead .tbl-search-fl td:nth-child(3),
.modal-itemeditcode .tablefixedhead .tbl-search-fl td:nth-child(3) {
  text-align: center;
}

.list-roles-page .form-training,
.list-roles-page .form-input,
.list-roles-page .form-input-fl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.list-roles-page .form-training .form-col,
  .list-roles-page .form-input .form-col,
  .list-roles-page .form-input-fl .form-col {
  max-width: 240px;
  min-width: 220px;
  margin-right: 16px;
}

.list-roles-page .form-training .form-col.form-col-fl,
    .list-roles-page .form-input .form-col.form-col-fl,
    .list-roles-page .form-input-fl .form-col.form-col-fl {
  max-width: 180px;
  min-width: 180px;
}

.list-roles-page .form-training .form-input label,
  .list-roles-page .form-input .form-input label,
  .list-roles-page .form-input-fl .form-input label {
  width: 50px;
}

.list-roles-page .form-training .form-input .form-control,
  .list-roles-page .form-input .form-input .form-control,
  .list-roles-page .form-input-fl .form-input .form-control {
  width: calc(100% - 50px);
}

.list-roles-page .form-training .form-btn .btn,
  .list-roles-page .form-input .form-btn .btn,
  .list-roles-page .form-input-fl .form-btn .btn {
  min-width: 100px;
}

.list-roles-page .form-training .form-input-fl label,
  .list-roles-page .form-input .form-input-fl label,
  .list-roles-page .form-input-fl .form-input-fl label {
  width: 90px;
}

.list-roles-page .form-training .form-input-fl .form-control,
  .list-roles-page .form-input .form-input-fl .form-control,
  .list-roles-page .form-input-fl .form-input-fl .form-control {
  width: calc(100% - 90px);
}

.list-roles-page .tablefixedhead .box-tbodytable {
  max-height: calc(100vh - 246px);
}

.list-roles-page .tablefixedhead .table-lst-roles tr th,
.list-roles-page .tablefixedhead .table-lst-roles tr td {
  min-width: 50px;
  max-width: 50px;
  text-align: center;
}

.list-roles-page .tablefixedhead .table-lst-roles tr th:nth-child(2),
  .list-roles-page .tablefixedhead .table-lst-roles tr td:nth-child(2) {
  min-width: 200px;
  max-width: 200px;
}

.list-roles-page .tablefixedhead .table-lst-roles tr th:nth-child(3),
  .list-roles-page .tablefixedhead .table-lst-roles tr td:nth-child(3) {
  min-width: 250px;
  max-width: 250px;
}

.list-roles-page .tablefixedhead .table-lst-privilege tr th,
.list-roles-page .tablefixedhead .table-lst-privilege tr td {
  min-width: 50px;
  max-width: 50px;
  text-align: center;
}

.list-roles-page .tablefixedhead .table-lst-privilege tr th:nth-child(1),
  .list-roles-page .tablefixedhead .table-lst-privilege tr td:nth-child(1) {
  min-width: 25px;
  max-width: 25px;
}

.list-roles-page .tablefixedhead .table-lst-privilege tr th:nth-child(2),
  .list-roles-page .tablefixedhead .table-lst-privilege tr td:nth-child(2) {
  min-width: 150px;
  max-width: 150px;
}

.list-roles-page .tablefixedhead .table-lst-privilege tr th:nth-child(3),
  .list-roles-page .tablefixedhead .table-lst-privilege tr td:nth-child(3) {
  min-width: 100px;
  max-width: 100px;
}

.list-roles-page .tablefixedhead .table-lst-privilege tr th:nth-child(4),
  .list-roles-page .tablefixedhead .table-lst-privilege tr td:nth-child(4) {
  min-width: 300px;
  max-width: 300px;
}

.list-roles-page .tablefixedhead .table-lst-privilege tr th:nth-child(5),
  .list-roles-page .tablefixedhead .table-lst-privilege tr td:nth-child(5) {
  width: 80px;
  min-width: 80px;
  max-width: 80px;
}

.list-roles-page .tablefixedhead .table-lst-privilege tr td:nth-child(4) {
  text-align: left;
}

.ant-form-item-control {
  line-height: 1.6 !important;
}

.ant-btn.btn-green:hover, .ant-btn.btn-green:focus {

    color: #fff ;
    background-color: #26B99A;
    border-color: #26B99A ;
}
.wrapper  .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #26B99A;
  border-color: #26B99A;
}
.wrapper .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}
.wrapper  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .wrapper  .ant-checkbox:hover .ant-checkbox-inner {
  border-color: 1px solid #d9d9d9;
  color: #fff;
  /* background-color: #26B99A; */
  border-color: #26B99A;
}
.wrapper  .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #26B99A;
}
.loading-blur {
	position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
    display: none \9;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0.4;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    pointer-events: auto;
    content: '';
}
.main .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1ABC9C;
  border-color: #1ABC9C;
}
.main .ant-checkbox-inner::after {
  left: 30%;
}
tr.hidden-tr {
  display: none;
}
.text-error {
  font-size: 14px;
  color: #f5222d;
  margin-bottom: -1px;
}
.table-custom-input .icon-upto, .table-custom-input .icon-downbottom, .table-custom-input .icon-plusmini, .table-custom-input .btn-more {
  margin-top:5px;
}
.table-custom-input .ant-checkbox {
  margin-top: 5px;
}
.table-custom-input .icon-deletered, .table-custom-input .icon-searchgreen  {
  margin-top: 2px;
}
.table-property .table tbody tr:last-child td {
  border-bottom: 1px solid #ddd !important;
}
.box-Property .tablefixedhead .box-tbodytable {
  min-height: calc(100vh - 220px);
}
.main .ant-checkbox-disabled  .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-checkbox-wrapper-disabled:hover .ant-checkbox-inner, .ant-checkbox-wrapper-disabled  .ant-checkbox:hover .ant-checkbox-inner {
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}
.icon-disabled {
  background-color: #c3c3c3;
}
.menu-disabled {
  color: #c3c3c3 !important;
  cursor: context-menu !important;
}
.ant-modal-content .ant-modal-header {
  padding: 8px 15px;
}
.ant-modal-content .ant-modal-title {
  font-size: 18px;
  font-weight: bold;
}
.notification-success .ant-notification-notice-icon-success {
  bottom: 12px;
}
.notification-success .ant-notification-notice-message {
  display: none;
}

.grpagetion .page ul li:first-of-type a {
	color: #077CF8;
}
.grpagetion .page ul li:last-child a {
	color: #077CF8;
}

.grpagetion .page ul li:first-of-type a.is-first-page {
	color:#73879C;
	cursor: default;
}

.grpagetion .page ul li:nth-child(2) a.is-first-page {
	color:#73879C;
	cursor: default;
}

.grpagetion .page ul li:nth-child(2) a {
	color: #077CF8;
}
.grpagetion .page ul li:nth-last-child(2) a {
	color: #077CF8;
}
.grpagetion .page ul li:nth-last-child(2) a.is-last-page {
	color:#73879C;
	cursor: default;
}
.grpagetion .page ul li:last-child a.is-last-page {
	color:#73879C;
	cursor: default;
}

.ant-btn.btn-red-fl {
  background-color: #DE8282;
  border: 1px #DE8282 solid;
  color: #fff;
}
.main .ant-row .has-error .ant-form-explain {
  margin-top: 3px;
  font-size: 12px;
}

.main .ant-input-group-addon .ant-select-open .ant-select-selection,
.main .ant-input-group-addon .ant-select-focused .ant-select-selection {
  color: #1abc9c;
}
.main .ant-radio-input:focus + .ant-radio-inner {
  border-color: #1abc9c;
}
.main .ant-radio-wrapper:hover .ant-radio,
.main .ant-radio:hover .ant-radio-inner {
  border-color: #d9d9d9;
}
.main .ant-radio-checked::after {
  border: 1px solid #1abc9c;
}
.main .ant-radio-disabled::after {
  border: 1px solid #d9d9d9;
}
.main .ant-radio-inner::after {
  background-color: #1abc9c;
}
.main .ant-radio-checked .ant-radio-inner {
  border-color: #1abc9c;
  background: url(../images/icon-radio.png) no-repeat 50%
}


.main .ant-radio-button-wrapper-checked {
  z-index: 1;
  color: #1abc9c;
  background: #fff;
  border-color: #1abc9c;
  box-shadow: -1px 0 0 0 #1abc9c;
}
.main .ant-radio-button-wrapper-checked::before {
  background-color: #1abc9c !important;
  opacity: 0.1;
}
.main .ant-radio-button-wrapper-checked:first-child {
  border-color: #1abc9c;
  box-shadow: none !important;
}

.main .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #1abc9c;
  border-color: #1abc9c;
}

.main .ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;

  border-radius: 100px;
  transition: all 0.3s;
}
.main .ant-radio-inner:hover {
  border-color: #d9d9d9;
}
.main .ant-radio-inner, .main .ant-radio-inner::after, .main .ant-radio-checked .ant-radio-inner::after {
  transition: none !important;
}

.main .ant-radio-checked::after {
  transition: none !important;
  animation: none !important;
}
.ant-radio-checked.ant-radio-disabled .ant-radio-inner {
    background: none;
}
.ant-radio-checked.ant-radio-disabled .ant-radio-inner::after {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    top: 4px;
    left: 4px;
}

.main .ant-input:hover {
  border-color: #CCCCCC;
}
.ant-modal-content .ant-modal-body .modal-body .grpform-input .txt-label {
  width: 60px;
  margin-right: 10px;
  line-height: 30px;
}
.ant-modal-content .ant-modal-body .modal-body .grpform-input .frm-btn {
  width: 65px;
  margin-left: 10px;
}  
.ant-modal-content .ant-modal-body  {
  padding-top: 16px;
  padding-bottom: 16px;
}
.ant-modal-content .ant-modal-body{
  padding: 10px 15px;
}
.ant-modal-content .ant-modal-body .modal-body {
  padding: 0px;
}
.ant-modal-content .frm-input .form-control.form-control-danger {
  border: 1px #ff3b30 solid;
  background: #ffeae8;
}
.ant-modal-content .ant-modal-header {
  padding: 6px 15px;
  color: #fff;
  background: #2A3F54;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0.3rem 0.3rem 0 0;
}
.modal-confirm-custom .ant-modal-body .modal-body .grp-buttonfooter .btn-green {
    background-color: #F5F5F5;
    border: 1px #CCCCCC solid;
    color: #000000;
}
.modal-confirm-custom .ant-modal-body .modal-body .grp-buttonfooter .btn-green:focus,
.modal-confirm-custom .ant-modal-body .modal-body .grp-buttonfooter .btn-green.focus {
    border: 2px #169F85 solid;
}

.ant-modal-content .ant-modal-title {
  color: #fff;
}
.modal-confirm-custom .ant-modal-content  .grpform-input {
  padding: 5px 0;
}
.modal-confirm-custom .ant-modal-content  .frm-input {
  width: 300px;
}
.ant-modal-content .modal-confirm-content {
  padding: 20px 30px;
  text-align: center;
}
.ant-modal-content {
  border-radius: 0.4rem !important;
}
.ant-modal-content .ant-modal-close-x {
    display: none;
}
.ant-modal-close::before {
    content: "×";
    position: absolute;
    right: 10px;
    top: 2px;
    padding: 0;
    margin: 0;
    color: #fff;
    opacity: 1;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    text-shadow: 0 1px 0 #fff;
}
.form-control:disabled, .form-control[readonly] {
  cursor: context-menu;
}
.dropdown  .page-disable.form-control {
  background-color: #e9ecef;
  opacity: 1;
  cursor: context-menu;
}
.dropdown  .page-disable.form-control:hover {
  color: #000;
}
.link-menueditor.active > .icon-page {
  background: url(../images/ic_arrowmenu2.png) no-repeat 0 0 !important;
}
.link-menueditor > .icon-page {
  background: url(../images/ic_arrowmenu.png) no-repeat 0 0 !important;
}

.item-submenueditor.active > .link-submenueditor > .icon-subarrow {
  background: url(../images/ic_arrowmenu2.png) no-repeat 0 0 !important;
}
.item-submenueditor > .link-submenueditor > .icon-subarrow {
  background: url(../images/ic_arrowmenu.png) no-repeat 0 0 !important;
}

.item-minisub.active > .link-minisubeditor > .icon-minisubarrow {
  background: url(../images/ic_arrowmenu2.png) no-repeat 0 0 !important;
}

.item-minisub > .link-minisubeditor > .icon-minisubarrow {
  background: url(../images/ic_arrowmenu.png) no-repeat 0 0 !important;
}
.box-pageeditor .box-contentleft .menueditor a.active, 
.box-blockeditor .box-contentleft .menueditor a.active, 
.box-questioneditor .box-contentleft .menueditor a.active, 
.box-itemeditor .box-contentleft .menueditor a.active {
  background: unset;
}


.sidebar .nav > ul > li a.active-menu {
  background: #374A5E;
}
.box-pageeditor .box-contentleft .menueditor a .groupiconeditor.display-block , 
.box-blockeditor .box-contentleft .menueditor a .groupiconeditor.display-block , 
.box-questioneditor .box-contentleft .menueditor a .groupiconeditor.display-block , 
.box-itemeditor .box-contentleft .menueditor a .groupiconeditor.display-block 
.box-itemeditor .display-block {
  display: inline-block !important;
}

.table tbody tr td.pre-line {
  white-space: pre-line;
}
button.btn:focus {
  opacity: 1;
}
.mt-15 {
  margin-top: 15px;
}

.ant-checkbox-disabled + span {
  cursor: default !important;
}

.cursor-default {
  cursor: default !important;
}
/**
 * style css antd datepicker
 */
th[role="columnheader"] {
	color: #26B99A;
	font-weight: bold !important;
}

th[role="columnheader"]:first-child > span {
	color: #ff0000;
	font-weight: bold;
}

th[role="columnheader"]:last-child > span {
	color: #000AFF;
	font-weight: bold;
}


tbody.ant-calendar-tbody tr[role="row"] td[role="gridcell"]:first-child > div.ant-calendar-date {
	color: #ff0000;
}

tbody.ant-calendar-tbody tr[role="row"] td[role="gridcell"]:last-child > div.ant-calendar-date {
	color: #000AFF;
}


.ant-calendar-selected-date .ant-calendar-date, .ant-calendar-selected-start-date .ant-calendar-date, .ant-calendar-selected-end-date .ant-calendar-date {
	color: #fff !important;
	background-color: #204d74 !important;;
	border-color: #122b40 !important;;
}

.ant-calendar-column-header .ant-calendar-column-header-inner {
	font-weight: bold !important;
}

#import-exel .ant-modal-content .ant-modal-body .modal-body .grpform-input .txt-label {
  width: 60px;
  margin-right: 10px;
  line-height: 30px;
}

#import-exel .ant-modal-content .ant-modal-body .modal-body .grpform-input .frm-btn {
  width: 65px;
  margin-left: 10px;
}

#import-exel .ant-modal-content .ant-modal-body  {
  padding-top: 16px;
  padding-bottom: 16px;
}

#import-exel .ant-modal-content .ant-modal-body{
  padding: 10px 15px;
}
#import-exel .ant-modal-content .ant-modal-body .modal-body {
  padding: 0px;
}
#import-exel .frm-input .form-control.form-control-danger {
  border: 1px #ff3b30 solid;
  background: #ffeae8;
}
#idFileUpload{
  width: calc(100% - 60px - 10px - 50px - 10px - 65px - 10px);
}
#import-exel .grp-buttonfooter .btn{
  min-width: 110px;
}

.main input span td th ul li{
  white-space: pre;
}

.upload-input-form {
  width: calc(100% - 205px);
}

.has-error .ant-form-explain {
  margin-top: 3px;
  font-size: 12px;
}

.item-add {
  background: url(../images/Group-1438.png) no-repeat;
  background-size: 21px;
  display: block;
  cursor: pointer;
  width: 21px;
  height: 21px;
  margin: 0 auto;
}

.item-add-disable {
  background: url(../images/add-disable.png) no-repeat;
  background-size: 21px;
  display: block;
  cursor: not-allowed;
  width: 21px;
  height: 21px;
  margin: 0 auto;
}

.upload-modal {
  .txt-label {
    width: auto !important;
  }

  .txt-required {
    width: auto !important;
  }
}